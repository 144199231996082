import React from "react";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback,
  AvField,
} from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  ButtonGroup,
} from "reactstrap";
import LocationService from "../../services/LocationService";
import RolesService from "../../services/RolesService";
import CONSTANTS from "../../constants";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import StaffService from "../../services/StaffService";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import { getAllLocations } from "../../redux/actions/locations";
import { getRoles, createStaff } from "../../redux/actions/staff";
import Toggle from "react-toggle";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import ViewHelper from "../../helpers/ViewHelper";
import "../../assets/scss/plugins/forms/react-select/_react-select.scss";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class StaffForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    staff: {
      staff_id: "",
      staff_name: "",
      staff_email: "",
      username: "",
      password: "",
      cpassword: "",
      role: "",
      location: 0,
    },
    location: {
      loc_id: 0,
      name: this.props.t("all-venues"),
      Currency: {
        currency_code: "PKR",
      },
      decimal_places: 2,
    },
    loading: false,
    saving: false,
    errorSaving: false,
    errorWallet: false,
    users: [],
    isOpen: false,
    selectedRole: null,
    isAdmin: true,
    searchTerm: "",
    userLocations: [],
    roles: [],
    errorDialog: {
      show: false,
      rejectCallback: () => {},
      title: this.props.t("app-reports"),
      header: this.props.t("app-reports"),
    },
    confirmationDialog: {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: this.props.t("wallet"),
      header: this.props.t("wallet"),
    },
  };

  async getStaffFromId(id) {
    let staff = await StaffService.getStaff(id);
    return staff;
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    let editMode = false;
    let staff = this.state.staff;
    const roles = await RolesService.getAllRoles("all");
    await this.setState({ roles: ViewHelper.transformedRoles(roles) });
    if (window.location.pathname.indexOf("edit") !== -1) {
      await this.setState({ editMode: true });
      const parts = window.location.pathname.split("/");
      staff = await this.getStaffFromId(parts[parts.length - 1]);
      staff.staff_id = parts[parts.length - 1];
      staff.username = staff.User.username;
      staff.password = "";
      editMode = true;
      await this.setState({ staff });
      await this.setState({ editMode });
    }
    await this.setState({ loading: false });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedRole !== this.state.selectedRole) {
      console.log("Selected Role Updated:", this.state.selectedRole);
    }
  }
  

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    let staff = JSON.parse(JSON.stringify(this.state.staff));
    let obj = staff;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ staff });
  }

  async updateRoleValue(value, type) {
    if (value == 11) {
      await this.setState({ isAdmin: true });
    } else {
      await this.setState({ isAdmin: false });
    }
    let staff = JSON.parse(JSON.stringify(this.state.staff));
    let obj = staff;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ staff });
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  async saveStaff() {
    const {
      state: { staff, editMode },
    } = this;

    if (!editMode && staff.password !== staff.cpassword) {
      this.showErrorDialog(
        null,
        this.props.t("your-password-does-not-match-with-confirm-password"),
        this.props.t("warning")
      );
      return;
    }
    if (editMode && staff.password !== "") {
      if (staff.password !== staff.cpassword) {
        this.showErrorDialog(
          null,
          this.props.t("your-password-does-not-match-with-confirm-password"),
          this.props.t("warning")
        );
        return;
      }
    }
    if (!editMode && staff.role == "") {
      this.showErrorDialog(
        null,
        this.props.t("please-select-your-role"),
        this.props.t("warning")
      );
      return;
    }
    let res = await StaffService.createStaff(staff);
    this.showErrorDialog(
      async () => {
        if (res.status === "success") {
          await this.props.history.replace("/staff");
        }
      },
      res.message,
      this.props.t("alert")
    );
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, "plan_time");
  };

  handleInputChange = (searchTerm) => {
    this.setState({ searchTerm });
    return searchTerm;
  };

  
  async loadOptions(term, callback) {
    const { selectedRole } = this.state;

    console.log("SELECTED ROLE: " + selectedRole)
    
    if (term.length < 3) return callback([]);
    try {
      const locations = await LocationService.getLocations(term);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      console.log(e);
      alert(this.props.t("could-not-load-locations"));
    }
  }

  async hotelLoadOptions(term, callback) {
 
    
    if (term.length < 3) return callback([]);
    try {
      const locations = await LocationService.getLocations(term,true);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      console.log(e);
      alert(this.props.t("could-not-load-locations"));
    }
  }
 
  onChange(selected) {
    if (selected) {
      if (this.props.onSelect) this.props.onSelect(selected.location);
      else this.onSelect(selected.location);
    }
  }
  // onChangeRole(selected) {
  //   if (selected) {
  //     this.onSelectRole(selected.value);
  //   }
  //   console.log("SELECTED ROLE",selected);

  // }
  onChangeRole(selected) {
    if (selected) {
      console.log("SELECTED",selected)
      this.onSelectRole(selected.value);
      this.setState({ selectedRole: selected.value }, () => {
        console.log("Selected Role:", this.state.selectedRole);
      });
    }
  }
  
  

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  async onSelect(location) {
    await this.updateValue(location.loc_id, "location");
  }
  async onSelectRole(role) {
    await this.updateRoleValue(role, "role");
  }

  render() {
    const { t } = this.props;
    const {
      props: { response },
    } = this;
    const {
      state: {
        staff,
        errorSaving,
        editMode,
        confirmationDialog,
        errorDialog,
        isAdmin,
        loading,
        selectedRole
      },
    } = this;

    console.log("SELECTED ROLE",selectedRole);
   

    const { staff_id, staff_name, staff_email, username, password, cpassword } =
      staff;


    if (loading) return <SpinnerComponent />;
    return (
      <div className="location">
        <Card>
          <CardHeader className="flex-column align-items-start">
            {errorSaving ? (
              <span className="danger">{t("unable-to-save-staff")}</span>
            ) : null}
            {/* {response && response.status == 'warning' && <span className='warning'>{response.message}</span>}
            {response && response.status == 'success' && <span className='success'>{response.message}</span>} */}
          </CardHeader>
          <CardBody>
            <AvForm onValidSubmit={() => this.saveStaff()}>
              {window.location.pathname.indexOf("edit") === -1 && (
                <Row>
                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label>{t("staff-name")}</Label>
                      <AvInput
                        name="staff_name"
                        type="text"
                        value={staff_name}
                        required
                        onChange={(e) =>
                          this.updateValue(e.target.value, "staff_name")
                        }
                      />
                      <AvFeedback>{t("please-enter-staff-name")}</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("staff-email")} </Label>
                      <AvInput
                        name="staff_email"
                        type="email"
                        value={staff_email}
                        required
                        onChange={async (e) => {
                          let useremail = e.target.value;
                          await this.updateValue(useremail, "staff_email");
                          await this.updateValue(useremail, "username");
                        }}
                        readOnly={!editMode ? false : true}
                      />
                      <AvFeedback>{t("please-enter-staff-email")}</AvFeedback>
                    </AvGroup>
                  </Col>

                  {/* <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t("username")} </Label>
                      <AvInput
                        autoComplete="username"
                        name="username"
                        required
                        type="text"
                        value={username}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "username")
                        }
                        readOnly={!editMode ? false : true}
                      />
                      <AvFeedback>{t("please-enter-your-username")}</AvFeedback>
                    </AvGroup>
                  </Col> */}

                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("password")} </Label>
                      <AvInput
                        autoComplete="new-password"
                        name="password"
                        required
                        type="password"
                        value={password}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "password")
                        }
                      />
                      <AvFeedback>{t("please-enter-your-password")}</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("confirm-password")} </Label>
                      <AvInput
                        autocomplete="new-password"
                        name="cpassword"
                        required
                        type="password"
                        value={cpassword}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "cpassword")
                        }
                      />
                      <AvFeedback>
                        {t("please-enter-your-confirm-password")}{" "}
                      </AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("roles")} </Label>
                      <Select
                        name="color"
                        options={this.state.roles}
                        onChange={this.onChangeRole.bind(this)}
                        className="React"
                        classNamePrefix="select"
                      />
                    </AvGroup>
                  </Col>
                  {/* {!isAdmin && (
                    <Col md="6" sm="6">
                      <AvGroup>
                        <Label> {t("locations")} </Label>
                        <AsyncSelect
                          placeholder={t("please-enter-restaurant-name")}
                          autoFocus
                          isClearable
                          cacheOptions
                          onBlur={this.onBlur.bind(this)}
                          onChange={this.onChange.bind(this)}
                          loadOptions={this.loadOptions.bind(this)}
                          onInputChange={this.handleInputChange.bind(this)}
                          noOptionsMessage={() => t("no-restaurants-present")}
                          className="React"
                          classNamePrefix="select"
                          styles={{
                            control: (provided) => {
                              return { ...provided, borderRadius: "0.3rem" };
                            },
                            dropdownIndicator: (provided) => {
                              return { ...provided, display: "none" };
                            },
                            indicatorSeparator: (provided) => {
                              return {
                                ...provided,
                                display: "none",
                                color: "#fff",
                              };
                            },
                            input: (provided) => {
                              return { ...provided, color: "#fff" };
                            },
                          }}
                        />
                      </AvGroup>
                    </Col>
                  )} */}

{!isAdmin && (
<Col md="6" sm="6">
  <AvGroup>
    <Label>{selectedRole === 22 ? "Hotels" : t("locations")}</Label>
    {selectedRole === 22 && (
      <AsyncSelect
        placeholder="Please Enter Hotel Name"
        autoFocus
        isClearable
        cacheOptions
        onBlur={this.onBlur.bind(this)}
        onChange={this.onChange.bind(this)}
        loadOptions={this.hotelLoadOptions.bind(this)}
        onInputChange={this.handleInputChange.bind(this)}
        className="React"
        classNamePrefix="select"
        styles={{
          control: (provided) => {
            return { ...provided, borderRadius: "0.3rem" };
          },
          dropdownIndicator: (provided) => {
            return { ...provided, display: "none" };
          },
          indicatorSeparator: (provided) => {
            return {
              ...provided,
              display: "none",
              color: "#fff",
            };
          },
          input: (provided) => {
            return { ...provided, color: "#fff" };
          },
        }}
      />
    )}
    {selectedRole !== 22 && (
      <AsyncSelect
        placeholder={t("please-enter-restaurant-name")}
        autoFocus
        isClearable
        cacheOptions
        onBlur={this.onBlur.bind(this)}
        onChange={this.onChange.bind(this)}
        loadOptions={this.loadOptions.bind(this)}
        onInputChange={this.handleInputChange.bind(this)}
        className="React"
        classNamePrefix="select"
        styles={{
          control: (provided) => {
            return { ...provided, borderRadius: "0.3rem" };
          },
          dropdownIndicator: (provided) => {
            return { ...provided, display: "none" };
          },
          indicatorSeparator: (provided) => {
            return {
              ...provided,
              display: "none",
              color: "#fff",
            };
          },
          input: (provided) => {
            return { ...provided, color: "#fff" };
          },
        }}
      />
    )}
  </AvGroup>
</Col>
)}

                </Row>
              )}
              {window.location.pathname.indexOf("edit") !== -1 && (
                <Row>
                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label>{t("staff-name")}</Label>
                      <AvInput
                        name="staff_name"
                        type="text"
                        value={staff_name}
                        required
                        onChange={(e) =>
                          this.updateValue(e.target.value, "staff_name")
                        }
                      />
                      <AvFeedback>{t("please-enter-staff-name")}</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("staff-email")} </Label>
                      <AvInput
                        name="staff_email"
                        type="email"
                        value={staff_email}
                        required
                        onChange={async (e) => {
                          let useremail = e.target.value;
                          await this.updateValue(useremail, "staff_email");
                          await this.updateValue(useremail, "username");
                        }}
                        readOnly={!editMode ? false : true}
                      />
                      <AvFeedback>{t("please-enter-staff-email")}</AvFeedback>
                    </AvGroup>
                  </Col>

                  {/* <Col md="12" sm="12">
                    <AvGroup>
                      <Label> {t("username")} </Label>
                      <AvInput
                        autoComplete="username"
                        name="username"
                        required
                        type="text"
                        value={username}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "username")
                        }
                        readOnly={!editMode ? false : true}
                      />
                      <AvFeedback>{t("please-enter-your-username")}</AvFeedback>
                    </AvGroup>
                  </Col> */}
                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("password")} </Label>
                      <AvInput
                        autoComplete="new-password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "password")
                        }
                      />
                      <AvFeedback>{t("please-enter-your-password")}</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col md="6" sm="6">
                    <AvGroup>
                      <Label> {t("confirm-password")} </Label>
                      <AvInput
                        autocomplete="new-password"
                        name="cpassword"
                        type="password"
                        value={cpassword}
                        onChange={(e) =>
                          this.updateValue(e.target.value, "cpassword")
                        }
                      />
                      <AvFeedback>
                        {t("please-enter-your-confirm-password")}{" "}
                      </AvFeedback>
                    </AvGroup>
                  </Col>
                </Row>
              )}
              <Button color="primary" size="md">
                <span className="ml-1 mr-1 d-md-inline ">{t("submit")}</span>
              </Button>
            </AvForm>
          </CardBody>
        </Card>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={"OK"}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={"OK"}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    staff: {
      allroles: { data: roles },
      staff: { data: response },
    },
    locations: {
      location: { data: locations, loading: loading },
    },
  } = state;

  return { roles, locations, loading: loading, response };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getRoles,
    createStaff,
    getAllLocations,
  })(StaffForm)
);
