import React from "react";
import * as Icon from "react-feather";
import Waiter from "../assets/img/waiterIcon.png";
const boldTextStyle = {
  fontWeight: "bold",
};

const hotelNavigationConfig = [
  {
    id: "dashboard",
    title: "dashboard",
    type: "item",
    icon: <Icon.Codesandbox size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    starred: false,
    style: boldTextStyle, // Apply the inline style to make the text bold
  },

  {
    id: "tables",
    title: "services",
    type: "collapse",
    icon: <img src={Waiter} alt="ServallImage" width={35} />,
    permissions: ["admin"],
    starred: false,
    children: [
      {
        id: "ongoingSessions",
        title: "ongoing Sessions",
        type: "item",
        icon: <Icon.BookOpen size={25} />,
        permissions: ["admin"],
        navLink: "/rooms/session",
        starred: false,
      },
      {
        id: "sessions",
        title: "sessions History",
        type: "item",
        icon: <Icon.Key size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/sessions",
        starred: false,
      },

      {
        id: "survey_list",
        title: "session Surveys",
        type: "item",
        icon: <Icon.Activity size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/survey_list",
        starred: false,
      },
      // {
      //   id: "tablets_list",
      //   title: "Tablets",
      //   type: "item",
      //   icon: <Icon.Tablet size={20} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/tablets",
      //   starred: false,
      // },
    ],
  },
  {
    id: "settings",
    title: "settings",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "hotels",
        title: "Hotels",
        type: "item",
        icon: <Icon.Codesandbox size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/hotels",
      },
      {
        id: "rooms",
        title: "Rooms",
        type: "item",
        icon: <Icon.Box size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/room",
      },
      {
        id: "services",
        title: "Hotel Services",
        type: "item",
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/services",
      },
    ],
    starred: false,
  },
  {
    id: "reviews",
    title: "Manage Reviews",
    type: "item",
    icon: <Icon.BookOpen size={25} />,
    permissions: ["admin"],
    navLink: "/location/reviews",
    starred: false,
  },

  {
    id: "terms_conditions",
    title: "manage Terms & Conditions",
    type: "item",
    icon: <Icon.Type size={25} />,
    permissions: ["admin"],
    navLink: "/location/terms_conditions",
    starred: false,
  },
  {
    id: "checkout-message",
    title: "manage Checkout Message",
    type: "item",
    icon: <Icon.ShoppingBag size={25} />,
    permissions: ["admin", "editor"],
    navLink: "/checkout_message",
    starred: false,
  },

  {
    id: "survey",
    title: "manage Survey ",
    type: "item",
    icon: <Icon.Activity size={25} />,
    permissions: ["admin"],
    navLink: "/survey_questions",
    starred: false,
  },
  // {
  //   id: "payment",
  //   title: "manage Payment ",
  //   type: "item",
  //   icon: <Icon.CreditCard size={25} />,
  //   permissions: ["admin"],
  //   navLink: "/payment",
  //   starred: false,
  // },
  {
    id: "Events",
    title: "Manage Events",
    type: "collapse",
    icon: <Icon.CreditCard size={25} />,
    permissions: ["admin"],

    children: [
      {
        id: "Upcoming Events",
        title: "Add Upcoming Events",
        type: "item",
        icon: <Icon.Codesandbox size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/upcoming_events",
      },
      {
        id: "Events",
        title: "Events",
        type: "item",
        icon: <Icon.Codesandbox size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/payment",
      },
    ],
    starred: false,
  },

  {
    id: "license",
    title: "manage Licenses ",
    type: "item",
    icon: <Icon.Award size={25} />,
    permissions: ["admin"],
    navLink: "/license",
    starred: false,
  },

  {
    id: "analytics",
    title: "reporting",
    type: "collapse",
    icon: <Icon.FileText size={25} />,
    children: [
      {
        id: "overall-stats",
        title: "overall Stats",
        type: "item",
        icon: <Icon.PieChart size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/overall_stats",
      },
      {
        id: "item-stats",
        title: "item Stats",
        type: "item",
        icon: <Icon.BarChart size={25} />,
        permissions: ["admin", "editor"],
        navLink: "/locations/item_stats",
      },
    ],
    starred: false,
  },

  {
    id: "logout",
    title: "logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/logout",
    starred: false,
  },
];
export default hotelNavigationConfig;
