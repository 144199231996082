import CONSTANTS from "../constants";
import LoginService from "./LoginService";
import { customFetch as fetch } from "../helpers/FetchHelper";

export default {
  getLiveOrders,
  getOrder,
  updateStatus,
  getSessionsList,
  getSessionsDetail,
  closeLocationSession,
  deleteLocationSession,
  sendReceiptToCustomer,
  getExportSessionList,
  deleteOrderId,
};

async function getLiveOrders(params) {
  let { token } = LoginService.getToken();
  // console.log(fetch);
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.LIVEORDER}`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live orders: ${response}`);
}

async function getOrder(locationId, orderId) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(
      locationId
    )}/${orderId}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function updateStatus(locationId, orderId, statusId, reasonToCancel) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(
      locationId
    )}/${orderId}`,
    {
      method: "PUT",
      body: JSON.stringify({
        status_id: statusId,
        status_history: { comment: reasonToCancel },
      }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );

  response = await response.json();
  if (response) return response;
  throw new Error(`Could not update order`);
}

async function getSessionsList(params, searchdata = null) {
  let { token } = LoginService.getToken();
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_LIST(
    params.loc_id
  )}?page=${params?.page ? params.page : 0}`;

  if (searchdata != null && searchdata != "") {
    url += `&${searchdata}`;
  }

  let response = await fetch(url, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function getExportSessionList(params, searchdata = null) {
  let { token } = LoginService.getToken();
  let url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_LIST_EXPORT(
    params.loc_id
  )}?page=${params?.page ? params.page : 0}`;

  if (searchdata != null && searchdata != "") {
    url += `&${searchdata}`;
  }

  let response = await fetch(url, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function getSessionsDetail(loc_id, id) {
  // console.log("Xxxx", loc_id)
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.SESSIONS_DETAIL(loc_id, id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function closeLocationSession(session_id) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CLOSE_SESSION(session_id)}`,
    {
      method: "GET",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}

async function deleteLocationSession(session_id) {
  console.log(session_id, "idididididididdi")
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DELETE_SESSION}`,
    {
      method: "POST",
      body: JSON.stringify({ session_id: session_id }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}

async function deleteOrderId(id) {
  let { token } = LoginService.getToken()
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.ORDER_ID(id)}`,
    {
      method: "Post",
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error("Could not update");
}
async function sendReceiptToCustomer(location_id, session_id, email) {
  let { token } = LoginService.getToken();
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.SENDRECEIPTEMAIL.default(
      location_id
    )}`,
    {
      method: "POST",
      body: JSON.stringify({ session_id: session_id, email: email }),
      headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
    }
  );
  if (response.ok) {
    response = await response.json();
    return response;
  }
  throw Error("Receipt not emailed to customer.");
}
