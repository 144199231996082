import CONSTANTS from "../constants";
import { customFetch as fetch } from "../helpers/FetchHelper";
import LocationService from "./LocationService";
import _ from "lodash";
export default {
  getToken,
  setLoginData,
  removeLoginData,
  login,
  setSmsWallet,
  getSmsWallet,
  getRole,
  getLoggedUser,
  setRole,
  getLoggedInAuthData,
  sendForgetPasswordEmail,
  resetPasswordEmail,
  ownerLogin,
};

function setLoginData(tokenData, role) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
  let roles = "No Role";
  // role.map((item , key) => {

  //   roles.push(item.StaffGroup.staff_group_name);
  // });

  roles = role.length > 0 ? role[0].StaffGroup.staff_group_name : "No Role";
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
  console.log("setLoginData");
  localStorage.setItem(
    `${CONSTANTS.CACHE_KEY_PREFIX}_auth`,
    JSON.stringify(tokenData.user)
  );
  localStorage.setItem(`DashColor`, "light");
  if (tokenData.locations !== null) {
    LocationService.setLocation(tokenData.locations);
    LocationService.setLocation(
      tokenData.locations,
      CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS
    );
    LocationService.setTotalLocationsCount(tokenData.locations.length);
  }
  this.setSmsWallet(tokenData.sms_wallet);
}

// function setRole(role) {
//   let roles = [];
//   // role.map((item) => {
//   //   roles.push(item.StaffGroup.staff_group_name);
//   // });
//   roles = role
//     ? role.length > 0
//       ? role[0].StaffGroup.staff_group_name
//       : "No Role"
//     : "No Role";
//   localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
//   console.log("setRole");
// }

function setRole(role) {
  const findValue = (arr, value) => {
    return _.find(arr, (elem) => {
      return elem.StaffGroup.staff_group_id === value;
    });
  };
  let roles = [];
  let definedRoles = [16, 11, 13];
  for (let i = 0; i < definedRoles.length; i++) {
    if (findValue(role, definedRoles[i])) {
      let res = findValue(role, definedRoles[i]);
      roles.push(res.StaffGroup.staff_group_name);
      break;
    }
  }
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
}

function setSmsWallet(wallet) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`, wallet);
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  console.log("removeLoginData");
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_auth`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_socketConnection`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token) return { token };
  return null;
}

function getRole() {
  let role = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  console.log("getRole");
  if (role) return { role };
  return null;
}

function getSmsWallet() {
  let wallet = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`);
  if (wallet) return { wallet };
  return null;
}

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: CONSTANTS.REQUEST_HEADERS,
  });
  response = await response.json();
  if (response && response.token) {
    return {
      token: response.token,
      sms_wallet: response.sms_wallet,
      role: response.role,
      user: response.user,
      locations: response.locations,
    };
  } else if (response && response.isOwner == 1) {
    console.log("RESPOSNE", response);
    return response;
  }
  throw new Error(`Could not login: ${response}`);
}
async function ownerLogin(email, password, otp) {
  let data = { email, password, otp };
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.OWNERLOGIN}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: CONSTANTS.REQUEST_HEADERS,
    }
  );
  response = await response.json();
  if (response && response.token)
    return {
      token: response.token,
      sms_wallet: response.sms_wallet,
      role: response.role,
      user: response.user,
      locations: response.locations,
    };
  throw new Error(`Could not login: ${response}`);
}

async function getLoggedUser() {
  console.log("getLoggedUser");
  let { token } = this.getToken();

  let response = await fetch(`${CONSTANTS.API_URL}/session_user`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS, "X-ACCESS-TOKEN": token },
  });
  response = await response.json();
  // this.setRole(response.role);
}

function getLoggedInAuthData() {
  let authdata = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_auth`);
  if (authdata) return JSON.parse(authdata);
  return null;
}

async function sendForgetPasswordEmail(email) {
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.FORGETPASSEMAIL.default}`,
    {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: CONSTANTS.REQUEST_HEADERS,
    }
  );
  response = await response.json();
  if (response) return response.msg;
  throw new Error(`Could not login: ${response}`);
}

async function resetPasswordEmail(data) {
  let response = await fetch(
    `${CONSTANTS.API_URL}${CONSTANTS.URLS.RESETPASSEMAIL.default}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: CONSTANTS.REQUEST_HEADERS,
    }
  );
  response = await response.json();
  if (response) return response.msg;
  throw Error(`Could not send reset request: ${response}`);
}
