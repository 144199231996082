import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import {
  getAllCurrency,
  changeCurrencyStatus,
} from "../../redux/actions/currency";
import "../../assets/scss/pages/page-pricing.scss";
import ListCurrency from "./ListCurrency";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";

import { withTranslation } from "react-i18next";
import i18n from "i18next";

class Currency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencies: "",
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    await this.props.getAllCurrency();
  }

  handleChange = (state) => {
    let currencies = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      currencies.push(state.selectedRows[i].currency_id);
    }
    this.setState({ currencies: currencies });
  };

  async updateCurrencyStatus() {
    if (this.state.currencies.length > 0) {
      this.showConfirmationDialog(
        async () => {
          await this.props.changeCurrencyStatus(this.state.currencies);
        },
        null,
        this.props.t('are-you-sure-you-want-to-change-status-of-this-currencies'),
        this.props.t('currencies')
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t('please-select-atleast-one-currency-for-change-status'),
        this.props.t('warning')
      );
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }
  editCurrency(id) {
    this.props.history.replace("/currencies/edit/" + id);
  }

  render() {
    const { t } = this.props;
    const {
      props: { currencies, loading },
    } = this;
    const {
      state: { confirmationDialog, errorDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    if (currencies.length > 0) {
      return (
        <>
          <div id="pricing-table">
            {/* <Button className='mb-1' color='primary' size='md' onClick={() => this.props.history.push('/currencies/new')}>
              <span className='ml-1 mr-1 d-md-inline'>New</span>
            </Button>
            <Button className='ml-1 mb-1' color='danger' size='md' onClick={() => this.updateCurrencyStatus()}>
              <span className='ml-1 mr-1 d-md-inline'>Enable / Disable</span>
            </Button> */}
            {!loading && currencies ? (
              <ListCurrency
                currencies={currencies}
                handleChange={this.handleChange.bind(this)}
                editCurrency={this.editCurrency.bind(this)}
              />
            ) : null}
          </div>
          <ConfirmationModal
            header={confirmationDialog.header}
            title={confirmationDialog.title}
            isOpen={confirmationDialog.show}
            reject={confirmationDialog.rejectCallback.bind(this)}
            rejectTitle={t('cancel')}
            accept={confirmationDialog.acceptCallback.bind(this)}
          />
          <ConfirmationModal
            header={errorDialog.header}
            title={errorDialog.title}
            isOpen={errorDialog.show}
            reject={errorDialog.rejectCallback.bind(this)}
            rejectTitle={t('ok')}
          />
        </>
      );
    } else {
      return "";
    }
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    currency: {
      allcurrencies: { data: currencies, loading: loading },
    },
  } = state;

  return { currencies, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getAllCurrency,
    changeCurrencyStatus,
  })(Currency)
);
