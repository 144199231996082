import React from 'react';
import { Container, Row, Col, Jumbotron, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const hotelInfo = {
  name: 'Hotel',
  image: 'https://hotelchantelle.com/wp-content/uploads/2023/09/3551-768x461.webp',
//   description: 'A luxurious hotel with top-notch services and exquisite dining experiences.',
};

const restaurants = [
  {
    name: 'Restaurant 1',
    image: 'https://assets.cntraveller.in/photos/63d8e5103d7229d4cf308f01/16:9/w_1920,h_1080,c_limit/Prequel-lead.jpg',
  },
  {
    name: 'Restaurant 2',
    image: 'https://assets.gqindia.com/photos/62a9d4653e8cdc9b632eb2ad/master/pass/10%20restaurants%20in%20Mumbai%20that%20offer%20the%20best%20sunset%20views.jpg',
  },
  {
    name: 'Restaurant 3',
    image: 'https://www.visitphilly.com/wp-content/uploads/2023/08/Enswell-Interior-by-J-Varney-2200x1237px.jpg',
  },
  {
    name: 'Restaurant 4',
    image: 'https://assets.architecturaldigest.in/photos/63733ec2a2dd6ea6560eb6da/16:9/w_1615,h_908,c_limit/Ditas%20Interior%20Image%20-%201%20(8).png',
  },
];

const HotelPage = () => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif' }}>
      <Jumbotron style={{ backgroundImage: `url(${hotelInfo.image})`, backgroundSize: '100% 38rem', color: 'white', marginBottom: '20px' }}>
        <Container>
          <h1 className="display-3 text-light">{hotelInfo.name}</h1>
          <p className="lead">{hotelInfo.description}</p>
        </Container>
      </Jumbotron>

      <Container>
        <h2>Our Restaurants</h2>
        <Row>
          {restaurants.map((restaurant, index) => (
            <Col key={index} md="6">
              <Card style={{ marginBottom: '20px' }}>
                <CardImg top width="100%" src={restaurant.image} alt={restaurant.name} />
                <CardBody>
                  <CardTitle>{restaurant.name}</CardTitle>
                  <CardText>{restaurant.description}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default HotelPage;
