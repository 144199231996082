import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../../shared/ErrorHandler";
import {
  getStaff,
  changeStaffStatus,
  deleteAssignRole,
} from "../../../redux/actions/staff";
import "../../../assets/scss/pages/page-pricing.scss";
import RolesList from "./RolesList";
import { Button } from "reactstrap";
import ConfirmationModal from "../../shared/forms/ConfirmationModal";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class RoleAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: "",
      errorDialog: {
        show: false,
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: this.props.t("app-users"),
        header: this.props.t("app-users"),
      },
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const parts = window.location.pathname.split("/");
    await this.props.getStaff(parts[parts.length - 1]);
  }

  handleChange = (state) => {
    let locations = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      locations.push(state.selectedRows[i].id);
    }
    this.setState({ locations: locations });
  };
  async updateStatus() {
    if (this.state.locations.length > 0) {
      this.showConfirmationDialog(
        async () => {
          const parts = window.location.pathname.split("/");
          let deleteRoles = {
            staff_id: parts[parts.length - 1],
            locations: this.state.locations,
          };
          await this.props.deleteAssignRole(deleteRoles);

          await this.props.getStaff(parts[parts.length - 1]);
        },
        null,
        this.props.t("are-you-sure-you-want-to-delete-of-this-permissions"),
        this.props.t("permission")
      );
    } else {
      this.showErrorDialog(
        null,
        this.props.t("please-select-atleast-one-permission-for-delete"),
        this.props.t("warning")
      );
    }
  }

  // async updateStatus() {
  //   if (this.state.locations.length > 0) {
  //     this.showConfirmationDialog(
  //       async () => {
  //         const parts = window.location.pathname.split("/");
  //         let deleteRoles = {
  //           staff_id: parts[parts.length - 1],
  //           locations: this.state.locations,
  //         };
  //         await this.props.deleteAssignRole(deleteRoles);
  //       },
  //       null,
  //       this.props.t("are-you-sure-you-want-to-delete-of-this-permissions"),
  //       this.props.t("permission")
  //     );
  //   } else {
  //     this.showErrorDialog(
  //       null,
  //       this.props.t("please-select-atleast-one-permission-for-delete"),
  //       this.props.t("warning")
  //     );
  //   }
  // }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => {},
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  assignRole() {
    const parts = window.location.pathname.split("/");
    this.props.history.push("/staff/assign/new/" + parts[parts.length - 1]);
  }

  render() {
    const { t } = this.props;
    const {
      props: { staff, loading },
    } = this;
    const {
      state: { confirmationDialog, errorDialog },
    } = this;
    if (loading) return <SpinnerComponent />;
    console.log("STAFFLOCATIONS", staff.StaffLocations);
    console.log("STAFF", staff);
    return (
      <>
        <div id="pricing-table">
          <Button
            className="mb-1"
            color="primary"
            size="md"
            onClick={() => this.assignRole()}
          >
            <span className="ml-1 mr-1 d-md-inline ">{t("new")}</span>
          </Button>
          <Button
            className="ml-1 mb-1"
            color="danger"
            size="md"
            onClick={() => this.updateStatus()}
          >
            <span className="ml-1 mr-1 d-md-inline "> {t("delete")} </span>
          </Button>
          {!loading && staff ? (
            <RolesList
              staff={staff.StaffLocations}
              staffOnly={staff}
              handleChange={this.handleChange.bind(this)}
            />
          ) : null}
        </div>
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={t("cancel")}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={t("ok")}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    staff: {
      staff: { data: staff, loading: loading },
    },
  } = state;

  return { staff, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getStaff,
    changeStaffStatus,
    deleteAssignRole,
  })(RoleAssign)
);
