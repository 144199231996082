import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import {
  getAllLocations,
  getAllLocationsByStaffId,
} from "../../redux/actions/locations";
import "../../assets/scss/pages/page-pricing.scss";
import ListAllLocation from "./ListAllLocation";
import Breadcrumbs from "../@vuexy/breadCrumbs/BreadCrumb";
import { Link } from "react-router-dom";
import { isArray } from "lodash";
import CONSTANTS from "../../constants";
import { Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import i18n from "i18next";

class AllLocations extends Component {
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    if (this.props?.match?.params?.staffid) {
      var userid = this.props?.match?.params?.staffid;
      await this.props.getAllLocationsByStaffId(userid);
    } else {
      await this.props.getAllLocations();
    }
  }

  editLocation(id) {
    this.props.history.replace("/locations/" + id);
  }

  render() {
    const { t } = this.props;
    const {
      props: { locations, loading },
    } = this;

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <Col sm="12" className="mb-3">
          <Row>
            <Col sm="12">
              <strong>{t('assigned-restaurant')}</strong>
            </Col>
          </Row>
        </Col>
        <div id="pricing-table">
          {!loading && isArray(locations) && locations ? (
            <ListAllLocation
              locations={locations}
              editLocation={this.editLocation.bind(this)}
            />
          ) : null}
        </div>
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locations, loading: loading },
    },
  } = state;

  return { locations, loading: loading };
}

export default withTranslation("global")(
  connect(mapStateToProps, {
    getAllLocations,
    getAllLocationsByStaffId,
  })(AllLocations)
);
