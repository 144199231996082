import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Navbar,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import CheckBoxesVuexy from "../@vuexy/checkbox/CheckboxesVuexy";
import RadioVuexy from "../@vuexy/radio/RadioVuexy";
import Select from "react-select";
import LocationService from "../../services/LocationService";
import MenuService from "../../services/MenuService";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import MenuOptionsModal from "./MenuOptionsModal";
import { ShoppingCart } from "react-feather";
import dummyFoodImage from "../../assets/img/dummy-food.png";
import logo from "../../assets/img/Servall W logo.png";

const PointofSale = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState();
  const [menu, setMenu] = useState([]);
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    discount: 0,
  });
  const [cart, setCart] = useState([]);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRadioOption, setSelectedRadioOption] = useState(null);
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState([]);
  const [selectedSelectOption, setSelectedSelectOption] = useState(null);
  const [finalCart, setFinalCart] = useState([]);
  const TAX_RATE = 0.1;
  const handleSelectChange = (e, option) => {
    const selectedOptionId = e.target.value;
    const selectedOption = option.Option.OptionValues.find(
      (value) => value.option_value_id === selectedOptionId
    );
    console.log("SELECT: " + selectedOption);
    setSelectedSelectOption(selectedOption);
  };

  const handleRadioChange = (option) => {
    setSelectedRadioOption(option);
  };

  const handleCheckboxChange = (option) => {
    const index = selectedCheckboxOptions.findIndex(
      (existingOption) =>
        existingOption.option_value_id === option.option_value_id
    );

    if (index === -1) {
      setSelectedCheckboxOptions([...selectedCheckboxOptions, option]);
    } else {
      setSelectedCheckboxOptions(
        selectedCheckboxOptions.filter((_, i) => i !== index)
      );
    }
  };
  const receiveCartItems = (cartItems) => {
    console.log("Received cart items:", cartItems);
    setFinalCart(cartItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      const location = LocationService.getLocation();
      if (location) {
        setLocationData(location);
        try {
          const menuData = await MenuService.getLocationMenu(location.slug);
          setMenu(menuData);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching menu data:", error);
        }
      }
    };
    fetchData();
  }, []);

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const applyDiscount = () => {
    const discount = parseInt(customer.discount);
    if (!isNaN(discount)) {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        discount: discount,
      }));
    } else {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        discount: 0,
      }));
    }
  };

  // const addToCart = (item) => {
  //   console.log("ITEM", item);
  //   const itemToAdd = {
  //     menu_id: item.menu_id,
  //     menu_name: item.menu_name,
  //     menu_type: item.menu_type,
  //     quantity: 1,
  //     menu_price: item.menu_price,
  //     menu_tax_percentage: item.menu_tax,
  //     menu_photo: item.menu_photo,
  //     selectedCheckboxOptions,
  //     selectedRadioOption,
  //     selectedSelectOption,
  //   };
  //   console.log("ITEMS ADDED", itemToAdd);
  //   setCart([...cart, itemToAdd]);
  //   setShowOptionsModal(false);
  //   setSelectedRadioOption(false);
  //   setSelectedSelectOption(false);
  //   setSelectedCheckboxOptions([]);
  // };
  const addToCart = (item) => {
    const existingIndex = cart.findIndex(
      (cartItem) => cartItem.menu_id === item.menu_id
    );
    if (existingIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingIndex].quantity++;
      setCart(updatedCart);
    } else {
      const newItem = {
        menu_id: item.menu_id,
        menu_name: item.menu_name,
        menu_price: item.menu_price,
        quantity: 1,
        selectedCheckboxOptions,
        selectedRadioOption,
        selectedSelectOption,
      };
      setCart([...cart, newItem]);
    }
    setShowOptionsModal(false);
    setSelectedRadioOption(null);
    setSelectedSelectOption(null);
    setSelectedCheckboxOptions([]);
  };

  const incrementQuantity = (index) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity++;
    setCart(updatedCart);
  };

  const decrementQuantity = (index) => {
    const updatedCart = [...cart];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity--;
      setCart(updatedCart);
    }
  };

  const subtotal = cart.reduce(
    (acc, item) => acc + item.menu_price * item.quantity,
    0
  );
  const tax = subtotal * TAX_RATE;
  const total = (subtotal - customer.discount) * (1 + TAX_RATE);

  const showMenuOptionsModal = (item) => {
    console.log("ITEM", item);
    setSelectedItem(item);
    setShowOptionsModal(true);
  };

  return (
    <div className="container-fluid">
      <Navbar
        bg="primary"
        expand="lg"
        className="mb-3"
        style={{ backgroundColor: "#343a40" }}
      >
        <Navbar.Brand style={{ color: "white" }}>POS System</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Your search form */}
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <img
            src={logo}
            alt="Logo"
            style={{
              maxHeight: "70px",
              background: "black",
              // paddingRight: "10px",
              border: "2px solid white",
            }}
          />
        </Navbar.Collapse>
      </Navbar>
      <div className="row">
        <div className="col-md-9">
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <div className="container-fluid">
              {menu?.menu_data?.map((item, index) => (
                <div key={index} id={`category-${index}`}>
                  <div className="container">
                    <div className="mb-2">
                      <h4
                        className="mb-1"
                        style={{ fontWeight: "bold", color: "green" }}
                      >
                        {item.Category.name}
                      </h4>
                      <hr style={{ borderTop: "2px solid green" }} />{" "}
                    </div>

                    <div className="row">
                      {item.Menu.map((item2, index2) => (
                        <div key={index2} className="col-md-4 ">
                          <div className="card ">
                            <div className="card-body text-center">
                              <img
                                src={
                                  item2.menu_photo
                                    ? "http://localhost:4000/restaurant_data/" +
                                      item2.menu_photo
                                    : dummyFoodImage
                                }
                                alt={item2.name}
                                className="card-img-top mx-auto d-block"
                                style={{
                                  height: "100px",

                                  objectFit: "contain",
                                }}
                              />
                              <h5 className="card-title mt-1">
                                {item2.menu_name}
                              </h5>
                              <p className="card-text">${item2.menu_price}</p>
                            </div>
                            <div className="card-footer text-center">
                              <button
                                onClick={() => showMenuOptionsModal(item2)}
                                className="btn btn-primary"
                              >
                                <i className="bi bi-plus"></i> Add to Cart
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="col-md-3 d-flex flex-column">
          <div className="card  border-primary shadow mb-1">
            <div className="card-body">
              <Form>
                <Form.Group controlId="customerName">
                  <Form.Label className="font-weight-bold">
                    Customer Name
                  </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter customer name"
                    value={customer.name}
                    onChange={handleCustomerChange}
                    name="name"
                  />
                </Form.Group>
                <Form.Group controlId="customerEmail">
                  <Form.Label className="font-weight-bold">
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={customer.email}
                    onChange={handleCustomerChange}
                    name="email"
                  />
                </Form.Group>
                <Form.Group controlId="discount">
                  <Form.Label className="font-weight-bold">Discount</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder="Enter discount"
                      value={customer.discount}
                      onChange={handleCustomerChange}
                      name="discount"
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-primary"
                        className="font-weight-bold"
                        onClick={applyDiscount}
                      >
                        Apply
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
          </div>

          <div className="card h-100 border-primary shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="mb-0">Cart</h2>
                <ShoppingCart className="bi bi-cart4" size={24} />
              </div>
              {cart?.map((cartItem, index) => (
                <div key={index} className="mt-3">
                  <h5>{cartItem.menu_name}</h5>
                  <p>Price: ${cartItem.menu_price}</p>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Cart controls"
                  >
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => incrementQuantity(index)}
                    >
                      +
                    </button>
                    <span className="mx-2">{cartItem.quantity}</span>
                    <button
                      className="btn btn-outline-warning btn-sm"
                      onClick={() => decrementQuantity(index)}
                    >
                      -
                    </button>
                    <button
                      onClick={() => setCart([])}
                      className="btn btn-outline-danger btn-sm ml-2"
                    >
                      Remove All
                    </button>
                  </div>
                  <ul>
                    {cartItem.selectedCheckboxOptions.map((option, idx) => (
                      <li key={idx}>{option.value}</li>
                    ))}
                  </ul>
                  <p>{cartItem.selectedRadioOption?.value}</p>
                  <p>{cartItem.selectedSelectOption?.value}</p>
                </div>
              ))}
              {cart.length > 0 && (
                <div className="mt-3 border-top pt-3">
                  <h5 className="font-weight-bold">Order Summary</h5>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span>Subtotal:</span>
                    <span>${subtotal.toFixed(2)}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>Tax (10%):</span>
                    <span>${tax.toFixed(2)}</span>
                  </div>
                  <div className="d-flex justify-content-between font-weight-bold">
                    <span>Total:</span>
                    <span>${total.toFixed(2)}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {selectedItem && (
        <MenuOptionsModal
          show={showOptionsModal}
          closeModal={() => setShowOptionsModal(false)}
          itemName={selectedItem}
          receiveCartItems={receiveCartItems} // Pass the function as a prop
        />
      )} */}

      <Modal show={showOptionsModal} onHide={() => setShowOptionsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem?.menu_name} Options</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          <h1>{selectedItem?.menu_price}</h1>
          {selectedItem?.MenuOptions.map((option) => (
            <div key={option.menu_option_id}>
              <h2>{option.Option.option_name}</h2>
              {option.Option.display_type === "radio" && (
                <div>
                  {option.Option.OptionValues.map((value) => (
                    <div key={value.option_value_id}>
                      <input
                        type="radio"
                        id={value.option_value_id}
                        name={option.menu_option_id}
                        value={JSON.stringify(value)}
                        checked={
                          JSON.stringify(selectedRadioOption) ===
                          JSON.stringify(value)
                        }
                        onChange={() => handleRadioChange(value)}
                      />

                      <label htmlFor={value.option_value_id}>
                        {value.value} - ${value.price}
                      </label>
                    </div>
                  ))}
                  {option.required === 1 && !selectedRadioOption && (
                    <p style={{ color: "red" }}>Please select an option</p>
                  )}
                </div>
              )}
              {option.Option.display_type === "checkbox" && (
                <div>
                  {option.Option.OptionValues.map((value) => (
                    <div key={value.option_value_id}>
                      <input
                        type="checkbox"
                        id={value.option_value_id}
                        name={option.menu_option_id}
                        value={JSON.stringify(value)}
                        checked={selectedCheckboxOptions.some(
                          (opt) => opt.option_value_id === value.option_value_id
                        )}
                        onChange={() => handleCheckboxChange(value)}
                      />

                      <label htmlFor={value.option_value_id}>
                        {value.value} - ${value.price}
                      </label>
                    </div>
                  ))}
                  {option.max &&
                    option.max > 0 &&
                    selectedCheckboxOptions.length >= option.max && (
                      <p style={{ color: "red" }}>
                        Maximum {option.max} options allowed
                      </p>
                    )}
                </div>
              )}
              {option.Option.display_type === "select" && (
                <select onChange={(e) => handleSelectChange(e, option)}>
                  {option.Option.OptionValues.map((value) => (
                    <option
                      key={value.option_value_id}
                      value={value.option_value_id}
                    >
                      {value.value} - ${value.price}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </Modal.Body> */}
        <Modal.Body>
          <h1>{selectedItem?.menu_price}</h1>
          {selectedItem?.MenuOptions.map((option) => (
            <div key={option.menu_option_id}>
              <h2>{option.Option.option_name}</h2>
              {option.Option.display_type === "checkbox" && (
                <div>
                  {option.Option.OptionValues.map((value) => (
                    <CheckBoxesVuexy
                      key={value.option_value_id}
                      label={`${value.value} - $${value.price}`}
                      checked={selectedCheckboxOptions.some(
                        (opt) => opt.option_value_id === value.option_value_id
                      )}
                      onChange={() => handleCheckboxChange(value)}
                    />
                  ))}
                  {option.max &&
                    option.max > 0 &&
                    selectedCheckboxOptions.length >= option.max && (
                      <p className="text-danger">
                        Maximum {option.max} options allowed
                      </p>
                    )}
                </div>
              )}
              {option.Option.display_type === "radio" && (
                <div>
                  {option.Option.OptionValues.map((value) => (
                    <RadioVuexy
                      key={value.option_value_id}
                      label={`${value.value} - $${value.price}`}
                      name={option.menu_option_id}
                      checked={
                        JSON.stringify(selectedRadioOption) ===
                        JSON.stringify(value)
                      }
                      onChange={() => handleRadioChange(value)}
                    />
                  ))}
                  {option.required === 1 && !selectedRadioOption && (
                    <p className="text-danger">Please select an option</p>
                  )}
                </div>
              )}
              {option.Option.display_type === "select" && (
                <select onChange={(e) => handleSelectChange(e, option)}>
                  {option.Option.OptionValues.map((value) => (
                    <option
                      key={value.option_value_id}
                      value={value.option_value_id}
                    >
                      {value.value} - ${value.price}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowOptionsModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => addToCart(selectedItem)}>
            Add to Cart
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PointofSale;
