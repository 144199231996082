import React from "react";
import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import { Globe } from "react-feather";
import { US, FR, NL } from "country-flag-icons/react/3x2"; // Import the flag icons

import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import classnames from "classnames";
import CONSTANTS from "../../../constants";
import { withTranslation } from "react-i18next";
import { Sun, Moon } from "react-feather";
import OTPGenerator from "../../../components/shared/OtpTimer";

const handleNavigation = (e, path) => {
  e.preventDefault();
  // history.push(path)
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    countNotification: 0,
    isChecked: true,
    theme: localStorage.getItem("DashColor") || "light", // Get the theme from localStorage

    lang:
      localStorage.getItem("lang") == null || localStorage.getItem("lang") == ""
        ? "en"
        : localStorage.getItem("lang"),
  };

  componentDidMount() {
    console.log("PROPS COMING", this.props);
  }
  // handleSwitchChange = () => {
  //   console.log('CHECKING', this.state.isChecked);
  //   if (this.state.isChecked) {
  //     this.props.changeMode('dark');
  //     localStorage.setItem(`DashColor`, 'dark');
  //   } else {
  //     this.props.changeMode('light');
  //     localStorage.setItem(`DashColor`, 'light');
  //   }
  //   this.setState({ isChecked: !this.state.isChecked });
  // };
  handleSwitchChange = () => {
    const newTheme = this.state.theme === "dark" ? "light" : "dark";
    this.setState({ theme: newTheme });
    localStorage.setItem("DashColor", newTheme);
    this.props.changeMode(newTheme);
  };

  // handleLanguageChange = (event) => {
  //   let newLang = event.target.value;
  //   this.setState({ lang: newLang });
  //   // this.props.i18n.changeLanguage(newLang);
  //   localStorage.setItem('lang', newLang);
  //   window.location.reload();
  // };
  handleLanguageChange = (newLang) => {
    this.setState({ lang: newLang });
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };
  getLanguageLabel(lang) {
    switch (lang) {
      case "en":
        return "English";
      case "fr":
        return "French";
      case "du":
        return "Dutch";
      default:
        return "Language";
    }
  }

  getFlagIcon(lang) {
    switch (lang) {
      case "en":
        return <US width={25} height={24} />;
      case "fr":
        return <FR width={25} height={24} />;
      case "du":
        return <NL width={25} height={24} />;
      default:
        return null;
    }
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  sessionDetail(sessionid, notification_id) {
    this.props.sessionDetail(sessionid, notification_id);
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });

  render() {
    const { t } = this.props;

    const themeIcon =
      this.state.theme === "light" ? (
        <Moon size={30} />
      ) : (
        <Sun size={30} color="white" />
      );

    let { navBarNotifications } = this.props;
    let navBarNotificationsList = navBarNotifications?.notificationList
      ? navBarNotifications?.notificationList
      : null;
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          {/* <DropdownToggle tag="a" className="nav-link position-relative">
            <Icon.ShoppingCart size={21} />
            {this.state.shoppingCart.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {this.state.shoppingCart.length}
              </Badge>
            ) : null}
          </DropdownToggle> */}
          {/* <DropdownMenu
            tag="ul"
            right
            className={`dropdown-menu-media dropdown-cart ${
              this.state.shoppingCart.length === 0 ? "empty-cart" : ""
            }`}
          >
            <li
              className={`dropdown-menu-header ${
                this.state.shoppingCart.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div className="dropdown-header m-0">
                <h3 className="white">
                  {this.state.shoppingCart.length} Items
                </h3>
                <span className="notification-title">In Your Cart</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {renderCartItems}
            </PerfectScrollbar>
            <li
              className={`dropdown-menu-footer border-top ${
                this.state.shoppingCart.length === 0 ? "d-none" : "d-block"
              }`}
            >
              <div
                className="dropdown-item p-1 text-center text-primary"
                // onClick={() => history.push("/ecommerce/checkout")}
              >
                <Icon.ShoppingCart size={15} />
                <span className="align-middle text-bold-600 ml-50">
                  Checkout
                </span>
              </div>
            </li>
            <li
              className={`empty-cart ${
                this.state.shoppingCart.length > 0 ? "d-none" : "d-block"
              } p-2`}
            >
              Your Cart Is Empty
            </li>
          </DropdownMenu> */}
        </UncontrolledDropdown>

        <UncontrolledDropdown tag="li" className="nav-item">
          <DropdownToggle tag="a" className="nav-link">
            {this.getFlagIcon(this.state.lang)}{" "}
            {this.getLanguageLabel(this.state.lang)}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              value="en"
              onClick={() => this.handleLanguageChange("en")}
            >
              {this.getFlagIcon("en")} English
            </DropdownItem>
            <DropdownItem
              value="fr"
              onClick={() => this.handleLanguageChange("fr")}
            >
              {this.getFlagIcon("fr")} French
            </DropdownItem>
            <DropdownItem
              value="du"
              onClick={() => this.handleLanguageChange("du")}
            >
              {this.getFlagIcon("du")} Dutch
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <div
          style={{
            marginRight: "7px",
            marginTop: "15px",
            cursor: "pointer",
          }}
          onClick={this.handleSwitchChange}
        >
          {themeIcon}
        </div>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              {navBarNotifications?.length === 0
                ? ((<Icon.Loader size={25} />), 0)
                : navBarNotifications?.countNotification}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                {navBarNotifications?.length === 0 ? (
                  <>
                    <h3 className="text-white">
                      {t("please-wait-notifications-are-loading")}{" "}
                    </h3>
                    <span className="notification-title">
                      {t("app-notifications")}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <h3 className="text-white">
                      {navBarNotifications?.countNotification} {t("new")}
                    </h3>
                    <span className="notification-title">
                      {t("app-notifications")}{" "}
                    </span>
                  </>
                )}
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {navBarNotifications?.length !== 0 &&
                navBarNotifications?.notificationList?.length > 0 && (
                  <>
                    {navBarNotificationsList.map((notification, index) => (
                      <React.Fragment key={index}>
                        {notification?.status === "unseen" && (
                          <div
                            className="d-flex justify-content-between"
                            onClick={() =>
                              this.sessionDetail(
                                notification?.session_id,
                                notification?.not_id
                              )
                            }
                          >
                            <Media className="d-flex align-items-start">
                              <Media left href="#">
                                <Icon.PlusSquare
                                  className="font-medium-5 primary"
                                  size={21}
                                />
                              </Media>
                              <Media body>
                                <Media
                                  heading
                                  className="primary media-heading"
                                  tag="h6"
                                >
                                  Session# {notification?.session_id}
                                </Media>
                                <p className="notification-text">
                                  {notification?.message}
                                </p>
                              </Media>
                            </Media>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                    {navBarNotifications?.notificationList?.length === 0 && (
                      <div className="empty-cart p-2">
                        {t("no-notifications-found")}{" "}
                      </div>
                    )}
                  </>
                )}
            </PerfectScrollbar>

            <li className="dropdown-menu-footer">
              <DropdownItem
                className="text-center bg-white"
                style={{ padding: 20 }}
              >
                <span className="align-middle primary p-20">
                  <a href={`${CONSTANTS.BASE_URL}notification_list`}>
                    {t("show-all-notifications")}{" "}
                  </a>
                </span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">{this.props.userRole}</span>
            </div>
          </DropdownToggle>
        </UncontrolledDropdown>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item mr-2"
        >
          <a
            className="nav-link"
            exact="true"
            href={`${CONSTANTS.BASE_URL}logout`}
          >
            <Icon.Power size={21} />
          </a>
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default withRouter(withTranslation("global")(NavbarUser));
