// import React, { useState, useEffect } from "react";
// import { RefreshCw } from "react-feather";
// import LocationService from "../../services/LocationService";
// import LoginService from "../../services/LoginService"; // Import LoginService
// import CONSTANTS from "../../constants"; // Import CONSTANTS
// import TimeHelper from "../../helpers/TimeHelper";

// const OTPGenerator = () => {
//   const [otp, setOTP] = useState("");

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     const locationData = LocationService.getLocation();

//     try {
//       const location = await LocationService.getLocationFromServer(
//         locationData.loc_id
//       );
//       console.log("LOCATIONREFRESHTIME", location.otp_refresh_time);

//       console.log("LOCATIONOTP", location.location_otp);
//       console.log(
//         "TIME OF OTP CREATION",
//         TimeHelper.toFormattedDateTime(location.otp_create_time)
//       );
//       setOTP(location.location_otp);
//     } catch (error) {
//       console.error("Error fetching location from server:", error);
//     }
//   };

//   const resetOTP = async () => {
//     try {
//       await LocationService.locationOtpReset();
//       fetchData();
//     } catch (error) {
//       console.error("Error resetting OTP:", error);
//     }
//   };

//   const boxStyle = {
//     width: "130px",
//     height: "40px",
//     border: "1px solid #ccc",
//     margin: "0 1px",
//     padding: "3px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "24px",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="container text-center">
//       <div className="card mx-auto d-flex justify-content-center">
//         <div className="card-body ">
//           <h2 className="bg-primary text-white">OTP</h2>
//           <div className="otp-box bg-primary" style={boxStyle}>
//             {otp}
//           </div>
//           <div
//             className="otp-container mt-2"
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "center",
//             }}
//           ></div>
//           <button className="btn btn-primary btn-sm mt-2" onClick={resetOTP}>
//             <RefreshCw size={20} />
//           </button>
//         </div>
//         <div className="card-body"></div>
//       </div>
//     </div>
//   );
// };

// export default OTPGenerator;

// import React, { useState, useEffect } from "react";
// import { RefreshCw } from "react-feather";
// import LocationService from "../../services/LocationService";
// import LoginService from "../../services/LoginService"; // Import LoginService
// import CONSTANTS from "../../constants"; // Import CONSTANTS
// import TimeHelper from "../../helpers/TimeHelper";

// const OTPGenerator = () => {
//   const [otp, setOTP] = useState("");
//   const [expiryTime, setExpiryTime] = useState("");
//   const [countdown, setCountdown] = useState("");

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     const locationData = LocationService.getLocation();

//     try {
//       const location = await LocationService.getLocationFromServer(
//         locationData.loc_id
//       );

//       const otpCreateTime = new Date(location.otp_create_time);
//       const otpRefreshTime = parseInt(location.otp_refresh_time);

//       const expiryTimestamp =
//         otpCreateTime.getTime() + otpRefreshTime * 60 * 1000;
//       setExpiryTime(expiryTimestamp);

//       setOTP(location.location_otp);
//     } catch (error) {
//       console.error("Error fetching location from server:", error);
//     }
//   };

//   const resetOTP = async () => {
//     try {
//       await LocationService.locationOtpReset();
//       fetchData();
//     } catch (error) {
//       console.error("Error resetting OTP:", error);
//     }
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const now = new Date().getTime();
//       const distance = expiryTime - now;
//       if (distance <= 0) {
//         clearInterval(interval);
//         setExpiryTime("");
//         fetchData(); // Refresh OTP
//       } else {
//         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((distance % (1000 * 60)) / 1000);
//         setCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [expiryTime]);

//   const boxStyle = {
//     width: "130px",
//     height: "40px",
//     border: "1px solid #ccc",
//     margin: "0 1px",
//     padding: "3px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "24px",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="container text-center">
//       <div className="card mx-auto d-flex justify-content-center">
//         <div className="card-body ">
//           <h2 className="bg-primary text-white">OTP</h2>
//           <div className="otp-box bg-primary" style={boxStyle}>
//             {otp}
//           </div>
//           <div className="otp-expiry mt-2">
//             {expiryTime ? (
//               <span>Expires in: {countdown}</span>
//             ) : (
//               <span>Expired</span>
//             )}
//           </div>
//           <div
//             className="otp-container mt-2"
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "center",
//             }}
//           ></div>
//           <button className="btn btn-primary btn-sm mt-2" onClick={resetOTP}>
//             <RefreshCw size={20} />
//           </button>
//         </div>
//         <div className="card-body"></div>
//       </div>
//     </div>
//   );
// };

// export default OTPGenerator;
// <div className="container text-center">
//   <div className="card mx-auto d-flex justify-content-center d-flex">
//     <div className="card-body ">
//       {/* <h2 className="bg-primary text-white">OTP</h2> */}
//       <div className="otp-box bg-primary" style={boxStyle}>
//         {otp}
//       </div>
//       <div className="otp-expiry mt-2">
//         {expiryTime ? <h4>{countdown}</h4> : null}{" "}
//       </div>
//       <div
//         className="otp-container mt-2"
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//         }}
//       ></div>
//       <button className="btn btn-primary btn-sm mt-2" onClick={resetOTP}>
//         <RefreshCw size={20} />
//       </button>
//     </div>
//     <div className="card-body"></div>
//   </div>
// </div>
// import React, { useState, useEffect } from "react";
// import { RefreshCw } from "react-feather";
// import LocationService from "../../services/LocationService";
// import { Card } from "reactstrap";

// const OTPGenerator = () => {
//   const [otp, setOTP] = useState("");
//   const [expiryTime, setExpiryTime] = useState("");
//   const [countdown, setCountdown] = useState("");

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     const locationData = LocationService.getLocation();

//     try {
//       const location = await LocationService.getLocationFromServer(
//         locationData.loc_id
//       );

//       const otpCreateTime = new Date(location.otp_create_time);
//       const otpRefreshTime = parseInt(location.otp_refresh_time);

//       const expiryTimestamp =
//         otpCreateTime.getTime() + otpRefreshTime * 60 * 1000;
//       setExpiryTime(expiryTimestamp);

//       setOTP(location.location_otp);
//     } catch (error) {
//       console.error("Error fetching location from server:", error);
//     }
//   };

//   const resetOTP = async () => {
//     try {
//       await LocationService.locationOtpReset();
//       fetchData();
//     } catch (error) {
//       console.error("Error resetting OTP:", error);
//     }
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const now = new Date().getTime();
//       const distance = expiryTime - now;
//       if (distance <= 0) {
//         clearInterval(interval);
//         resetOTP();
//       } else {
//         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((distance % (1000 * 60)) / 1000);
//         setCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [expiryTime]);

//   const boxStyle = {
//     width: "130px",
//     height: "40px",
//     border: "1px solid #ccc",
//     margin: "0 1px",
//     padding: "3px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "24px",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <Card>
//       <div className="d-flex justify-content-center align-items-center">
//         <div className="otp-box bg-primary" style={boxStyle}>
//           {otp}
//         </div>
//         <div className="otp-expiry ml-2">
//           {expiryTime ? <h4 className="mt-1">{countdown}</h4> : null}
//         </div>
//         <div className="ml-2">
//           <button className="btn  btn-sm" onClick={resetOTP}>
//             <RefreshCw size={20} />
//           </button>
//         </div>
//       </div>
//     </Card>
//   );
// };

// export default OTPGenerator;

// import React, { useState, useEffect } from "react";
// import { RefreshCw } from "react-feather";
// import LocationService from "../../services/LocationService";
// import { Card } from "reactstrap";

// const OTPGenerator = () => {
//   const [otp, setOTP] = useState("");
//   const [expiryTime, setExpiryTime] = useState("");
//   const [countdown, setCountdown] = useState("");

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     const locationData = LocationService.getLocation();

//     try {
//       const location = await LocationService.getLocationFromServer(
//         locationData.loc_id
//       );

//       const otpCreateTime = new Date(location.otp_create_time);
//       const otpRefreshTime = parseInt(location.otp_refresh_time);

//       const expiryTimestamp =
//         otpCreateTime.getTime() + otpRefreshTime * 60 * 1000;
//       setExpiryTime(expiryTimestamp);

//       setOTP(location.location_otp);
//     } catch (error) {
//       console.error("Error fetching location from server:", error);
//     }
//   };

//   const resetOTP = async () => {
//     try {
//       await LocationService.locationOtpReset();
//       fetchData();
//     } catch (error) {
//       console.error("Error resetting OTP:", error);
//     }
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const now = new Date().getTime();
//       const distance = expiryTime - now;
//       if (distance <= 0) {
//         clearInterval(interval);
//         fetchData();
//       } else {
//         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((distance % (1000 * 60)) / 1000);
//         setCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [expiryTime]);

//   const boxStyle = {
//     width: "30px",
//     height: "30px",
//     border: "1px solid #ccc",
//     margin: "0 1px",
//     padding: "3px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "24px",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <Card>
//       <div className="d-flex justify-content-center align-items-center">
//         {otp.split("").map((character, index) => (
//           <div className="otp-box bg-primary" style={boxStyle} key={index}>
//             {character}
//           </div>
//         ))}
//         <div style={{ marginTop: "5px" }} className="otp-expiry ml-2 ">
//           {expiryTime ? <h4>{countdown}</h4> : null}
//         </div>
//         <div className="ml-2">
//           <button
//             className="btn btn-sm bg-primary text-white"
//             onClick={resetOTP}
//           >
//             <RefreshCw size={20} />
//           </button>
//         </div>
//       </div>
//     </Card>
//   );
// };

// export default OTPGenerator;
import React, { useState, useEffect } from "react";
import { RefreshCw, Copy } from "react-feather";
import LocationService from "../../services/LocationService";
import { Button, Card } from "reactstrap";
import { toast } from "react-hot-toast";

const OTPGenerator = () => {
  const [otp, setOTP] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const locationData = LocationService.getLocation();

    try {
      const location = await LocationService.getLocationFromServer(
        locationData.loc_id
      );

      const otpCreateTime = new Date(location.otp_create_time);
      const otpRefreshTime = parseInt(location.otp_refresh_time);

      const expiryTimestamp =
        otpCreateTime.getTime() + otpRefreshTime * 60 * 1000;
      setExpiryTime(expiryTimestamp);

      setOTP(location.location_otp);
    } catch (error) {
      console.error("Error fetching location from server:", error);
    }
  };

  const resetOTP = async () => {
    try {
      await LocationService.locationOtpReset();
      fetchData();
    } catch (error) {
      console.error("Error resetting OTP:", error);
    }
  };

  const copyOTP = () => {
    navigator.clipboard.writeText(otp);
    toast.success("OTP copied to clipboard");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = expiryTime - now;
      if (distance <= 0) {
        clearInterval(interval);
        fetchData();
      } else {
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTime]);

  const boxStyle = {
    width: "30px",
    height: "30px",
    border: "1px solid #ccc",
    margin: "0 1px",
    padding: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "white",
    fontWeight: "bold",
  };

  return (
    <Card>
      <div className="d-flex justify-content-center align-items-center">
        {otp.split("").map((character, index) => (
          <div className="otp-box bg-primary" style={boxStyle} key={index}>
            {character}
          </div>
        ))}
        <div style={{ marginTop: "5px" }} className="otp-expiry ml-2 ">
          {expiryTime ? <h4>{countdown}</h4> : null}
        </div>
        <div className="ml-2">
          <button
            className="btn btn-sm bg-primary text-white mr-1"
            onClick={copyOTP}
          >
            <Copy size={16} />
          </button>
          <button
            className="btn btn-sm bg-primary text-white"
            onClick={resetOTP}
          >
            <RefreshCw size={16} />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default OTPGenerator;
