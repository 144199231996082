import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LoginService from "../../services/LoginService";
import CONSTANTS from "../../constants";
import Swal from "sweetalert2";

export default class Logout extends Component {
  handleLogout = () => {
    LoginService.removeLoginData();
    Swal.fire({
      icon: "success",
      title: "Logged Out",
      text: "You have been successfully logged out.",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      window.location.href = "/";
    });
  };

  componentDidMount() {
    this.handleLogout();
  }
  // componentDidMount() {
  //   LoginService.removeLoginData();
  //   window.location.href = "/";
  // }

  render() {
    return <Redirect to={CONSTANTS.BASE_URL} />;
  }
}
