import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import DataTableExtensions from "react-data-table-component-extensions";
import { Check, Edit2 } from "react-feather";
import {
  Button,
  Modal,
  ModalBody,
  Card,
  CardHeader,
  CardTitle,
} from "reactstrap";
import Toggle from "react-toggle";
// import { Modal, ModalBody } from "reactstrap"; // Import the modal library
import QRCode from "qrcode.react"; // Import the QR code library
import ImageHelper from "../../helpers/ImageHelper";
import { Link, X } from "react-feather";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

const ListTables = (props) => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const lang2 = localStorage.getItem("lang");
    i18n.changeLanguage(lang2);
  }, []);

  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [qrImageUrl, setQrImageUrl] = useState("");
  const [qrImageName, setQrImageName] = useState("");
  const [redirectURL, setRedirectURL] = useState("");
  const editTable = (id) => {
    props.editTable(id);
  };
  const open = (url) => {
    props.openImage(url);
  };
  const openQrModal = (qrCodeUrl, qrImagename, url) => {
    console.log("url:", url);
    setSelectedTableId(qrCodeUrl);
    setQrImageUrl(qrCodeUrl);
    setQrImageName(qrImagename);
    setRedirectURL(url);
    setQrModalOpen(true);
  };

  const closeQrModal = () => {
    setSelectedTableId(null);
    setQrModalOpen(false);
    setQrImageUrl("");
    setQrImageName("");
    setRedirectURL("");
  };
  const redirectToURL = (url) => {
    closeQrModal();
    window.open(url, "_blank");
  };

  const columns = [
    // {
    //   name: t('edit'),

    //   selector: (row) => row.table_id,
    //   format: (val) => {
    //     return (
    //       <Button
    //         outline
    //         className="btn-icon bg-white"
    //         color="primary"
    //         onClick={() => editTable(val.table_id)}
    //       >
    //         <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   name: t('status'),

    //   selector: (row) => row.table_status,
    //   format: (val) => {
    //     return (
    //       <Toggle
    //         name={'table_status'}
    //         defaultChecked={val.table_status}
    //         className="switch-danger align-self-baseline"
    //         onChange={(e) => props.onChangeToggle(e, val.table_id, 'toggle')}
    //       />
    //     );
    //   },
    // },
    {
      name: t("name"),

      selector: (row) => row.table_name,
      format: (val) => {
        return val.table_name ? val.table_name : t("not-available");
      },
      sortable: true,
    },
    {
      name: t("Floor Name"),

      selector: (row) => row.Floor?.floor_name,
      width: "20%",
      format: (val) => {
        return val.Floor && val.Floor?.floor_name
          ? val.Floor?.floor_name
          : t("N/A");
      },
      sortable: true,
    },
    {
      name: t("min-limit"),

      selector: (row) => row.min_capacity,

      format: (val) => {
        console.log("VALUES", val);
        return val.min_capacity ? val.min_capacity : "0";
      },
    },
    {
      name: t("max-limit"),

      selector: (row) => row.max_capacity,

      format: (val) => {
        return val.max_capacity ? val.max_capacity : "0";
      },
    },
    {
      name: t("location"),

      selector: (row) => row.Location.name,

      format: (val) => {
        return val.Location && val.Location.name
          ? val.Location.name
          : t("not-available");
      },
      sortable: true,
    },

    {
      name: t("qr-code"),
      selector: (row) => row.qrcodedata,
      sortable: true,
      padding: "10px",
      format: (val) => {
        const qrCodeUrl = val.qrcodedata;
        const qrImageUrl = ImageHelper.getImageURL(val.image_name);
        const qrImagename = val.table_name;
        const url = val.qrcodedata;
        const qrCodeContainerStyle = {
          padding: "3px",
          paddingBottom: "0.5px",
          // opacity: val.is_table_available ? 1 : 0.5,
        };

        return (
          <>
            {qrCodeUrl != null && qrCodeUrl !== "" ? (
              <Button
                style={qrCodeContainerStyle}
                outline
                className="btn-icon"
                color="primary"
                onClick={() => openQrModal(qrImageUrl, qrImagename, url)}
                // onClick={() => val.is_table_available && openQrModal(qrImageUrl, qrImagename, url)}
                // disabled={!val.is_table_available}
              >
                <QRCode value={qrCodeUrl} size={35} />
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  const renderTables = (tables, handleChange) => {
    if (!tables || tables.length === 0)
      return <h1>{t("no-app-table-found")}</h1>;
    // Filter tables where table_status is true
    const filteredTables = tables.filter((table) => table.table_status);
    const tableData = {
      columns: columns,
      data: filteredTables,
      print: false,
      export: false,
    };
    const buttonStyle = {
      flex: 1,
      margin: "5px",
      display: "flex",
      alignItems: "center",
    };
    return (
      <>
        <Card>
          <CardHeader>{/* <CardTitle>{t('Tables')}</CardTitle> */}</CardHeader>
          <DataTableExtensions {...tableData}>
            <DataTable
              noHeader
              pagination
              paginationRowsPerPageOptions={[25, 50, 100]}
              paginationPerPage={50}
              // selectableRows
              // selectableRowsComponent={Checkbox}
              // selectableRowsComponentProps={{
              //   color: 'primary',
              //   icon: <Check className="vx-icon" size={12} />,
              //   label: '',
              //   size: 'sm',
              // }}
              // onSelectedRowsChange={handleChange}
              columns={columns}
              data={filteredTables}
            />
          </DataTableExtensions>
          <Modal
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "500px",
            }}
            isOpen={qrModalOpen}
            onRequestClose={closeQrModal}
            contentLabel={t("qr-code-modal")} // Add a custom class to the overlay
          >
            <ModalBody>
              <div
                style={{ background: "red !important" }}
                className="display-flex full-width flex-space-between "
              >
                {/* <h5 className="modal-title  "></h5> */}
                <Button.Ripple
                  style={{ width: 20, height: 20 }}
                  onClick={closeQrModal}
                  className="m-0 p-0"
                  color="danger"
                >
                  <X size={14} />
                </Button.Ripple>
              </div>
              <div style={{ textAlign: "center", padding: "50px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={buttonStyle}
                    className="btn-icon"
                    color="primary"
                    onClick={() => redirectToURL(redirectURL)}
                  >
                    <Link size={16} style={{ marginRight: "5px" }} />{" "}
                    {t("qr-url")}
                  </Button>
                  <h4 style={{ marginBottom: "-3px" }}>{qrImageName}</h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={qrImageUrl}
                      alt={t("qr-code")}
                    />
                  </div>
                  <Button
                    style={buttonStyle}
                    className="btn-icon"
                    color="primary"
                    onClick={() =>
                      saveAs(
                        qrImageUrl,
                        `${qrImageName}.jpeg`,
                        closeQrModal(true)
                      )
                    }
                  >
                    {t("download")}{" "}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Card>
      </>
    );
  };
  return renderTables(props.tables, props.handleChange);
};

export default ListTables;
