import React from "react";
import { Card, CardBody } from "reactstrap";
import Chart from "react-apexcharts";

class StatisticsCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleHover = (event) => {
    const isMouseOver = event.type === "mouseenter";
  
    this.setState({
      isHovered: isMouseOver,
    });
    console.log("ISHOVERED", this.state.isHovered);
  };
  
  // handleHover = () => {
  //   this.setState((prevState) => ({
  //     isHovered: !prevState.isHovered,
  //   }));
  // };

  render() {
    // console.log("ishover", this.props);
    const { isHovered } = this.state;

    const commonStyles = {};

    const hoverStyles = {
      textAlign: "center",
      border: "3px solid green",
      borderRadius: "5px",
      transition: "transform 0.2s ease 0s",
      cursor: "pointer",
      height: "110px",
    };

    const combinedStyles = isHovered
      ? { ...commonStyles, ...hoverStyles }
      : commonStyles;

    return (
      <Card>
        <CardBody
          className={`${
            this.props.className ? this.props.className : "stats-card-body"
          } d-flex ${
            !this.props.iconRight && !this.props.hideChart
              ? "flex-column align-items-start"
              : this.props.iconRight
              ? "justify-content-between flex-row-reverse align-items-center"
              : this.props.hideChart && !this.props.iconRight
              ? "justify-content-center flex-column text-center"
              : null
          } ${!this.props.hideChart ? "pb-0" : "pb-2"} pt-2`}
          style={combinedStyles}
          onMouseEnter={this.props.managerLinks ? this.handleHover : null}
          onMouseLeave={this.props.managerLinks ? this.handleHover : null}
        >
          <div className="icon-section">
            <div
              className={`avatar avatar-stats p-50 m-0 ${
                this.props.iconBg
                  ? `bg-rgba-${this.props.iconBg}`
                  : "bg-rgba-primary"
              }`}
            >
              <div className="avatar-content">{this.props.icon}</div>
            </div>
          </div>
          <div className="title-section">
            <h2 className="text-bold-600 mt-1 mb-25">{this.props.stat}</h2>
            <p className="mb-0 text-dark">{this.props.statTitle}</p>
          </div>
        </CardBody>
        {!this.props.hideChart && (
          <Chart
            options={this.props.options}
            series={this.props.series}
            type={this.props.type}
            height={this.props.height ? this.props.height : 100}
          />
        )}
      </Card>
    );
  }
}
export default StatisticsCards;


