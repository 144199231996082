import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { Button, Col, Row } from 'reactstrap';
import { ArrowLeft } from 'react-feather';

import ViewHelper from '../../helpers/ViewHelper';
import LocationService from '../../services/LocationService';
import CONSTANTS from '../../constants';

import '../../assets/css/selectLocation.scss';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class SelectLocation extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: '', userLocations: [] };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    let locations = await LocationService.getLocations('');
    this.setState({ userLocations: locations });
    if (locations.length === 1) {
      this.onChange({ location: locations[0] });
    }
  }

  handleInputChange = (searchTerm) => {
    this.setState({ searchTerm });
    return searchTerm;
  };

  async loadOptions(term, callback) {
    if (term.length < 3) return callback([]);
    try {
      const locations = await LocationService.getLocations(term);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
      console.log(e);
      alert(this.props.t('could-not-load-locations'));
    }
  }

  onChange(selected) {
    if (selected) {
      if (this.props.onSelect) this.props.onSelect(selected.location);
      else this.onSelect(selected.location);
    }
  }

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  goBack() {
    if (this.props.onBack) {
      console.log('Go Back');
      this.props.onBack();
    }
  }

  onSelect(location) {
    LocationService.setLocation(location);
    this.redirectToURL(location);
  }

  redirectToURL(location) {
    this.props.history.push(
      `${CONSTANTS.BASE_URL}locations/${location.loc_id}/order_types`
    );
  }

  render() {
    const { t } = this.props;
    const {
      props: { hideBack = false, showAll = false },
    } = this;
    return (
      <React.Fragment>
        <div className="d-flex flex-row">
          {!hideBack && (
            <ArrowLeft
              className="primary font-weight-bold align-self-auto mr-1"
              size="40"
              onClick={this.goBack.bind(this)}
            />
          )}
          <div className="position-relative has-icon-left full-width">
            <AsyncSelect
              placeholder={t('please-enter-restaurant-name')}
              autoFocus
              isClearable
              cacheOptions
              onBlur={this.onBlur.bind(this)}
              onChange={this.onChange.bind(this)}
              loadOptions={this.loadOptions.bind(this)}
              onInputChange={this.handleInputChange.bind(this)}
              noOptionsMessage={() => {
                t('no-restaurants-present');
              }}
              styles={{
                control: (provided) => {
                  return { ...provided };
                },
                dropdownIndicator: (provided) => {
                  return { ...provided, display: 'none' };
                },
                indicatorSeparator: (provided) => {
                  return { ...provided, display: 'none' };
                },
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation('global')(SelectLocation);
