import React from "react";
import Select from "react-select";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import "../../assets/scss/plugins/forms/react-select/_react-select.scss";
import "../../assets/scss/pages/app-ecommerce-shop.scss";
import { X } from "react-feather";
import FREETABLE from "../../assets/img/pages/table_empty.png";
import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faGlassCheers,
  faBirthdayCake,
  faClock,
  faCoins,
  faTimes,
  faStopwatch,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import TimeHelper from "../../helpers/TimeHelper";

class ListSessionRoomsStatus extends React.Component {
  state = {
    inCart: [],
    inWishlist: [],
    view: "grid-view",
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }
  getOrderFromDisplayName = (orderFrom) => {
    switch (orderFrom) {
      case "QR Scan":
        return "QuickServe";
      case "Eater App":
        return "ServTable";
      case "Waiter App":
        return "ServWait";
      default:
        return orderFrom;
    }
  };

  renderRoomsData = (rooms) => {
    const { t } = this.props;
    let renderTables = rooms?.data?.map((rooms, index) => {
      const sessionTime = new Date(
        rooms?.LocationSessions[0]?.session_date +
          " " +
          rooms?.LocationSessions[0]?.start_time
      );
      const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
      if (rooms.room_status === false) {
        return null;
      }

      return (
        <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
          {/* {rooms.room_status && ( */}
          <Card className="ecommerce-card table-session">
            <div className="card-content">
              <div className="row">
                <div className="col-12">
                  <span className="float-left font-weight-bold">
                    <FontAwesomeIcon
                      className=" text-white"
                      icon={faClock}
                      style={{ fontSize: "20px" }}
                    />

                    {rooms.is_room_available
                      ? " 00"
                      : ` ${this.getSessionTime(sessionTime)}`}
                  </span>
                  <span className="float-right font-weight-bold">
                    <FontAwesomeIcon
                      className="mr-1"
                      style={{ fontSize: "20px", color: "gold" }}
                      icon={faCoins}
                    />
                    {rooms.is_room_available
                      ? 0
                      : rooms?.LocationSessions[0]?.Orders.length > 0
                      ? this.props.location.currency +
                        " " +
                        this.formatAmount(
                          rooms?.LocationSessions[0]?.Orders[0]
                            .session_order_amount
                        )
                      : 0}
                  </span>

                  {/* <span className="float-right font-weight-bold">
                      <FontAwesomeIcon
                        className="mr-1"
                        style={{ fontSize: "20px", color: "gold" }}
                        icon={faCoins}
                      />
                      {table.is_table_available
                        ? 0
                        : table?.LocationSessions[0]?.Orders.length > 0
                        ? this.props.location.currency +
                          " " +
                          table?.LocationSessions[0]?.Orders[0]
                            .session_order_amount
                        : 0}
                    </span> */}
                </div>
              </div>

              <div className="item-img text-center">
                {rooms.is_room_available ? (
                  <img
                    style={{ filter: "brightness(2)" }}
                    className="img-fluid"
                    src={FREETABLE}
                    alt={rooms.room_name}
                  />
                ) : (
                  <img
                    style={{ filter: "brightness(2)" }}
                    className="img-fluid"
                    src={BOOKEDTABLE}
                    alt={rooms.room_name}
                  />
                )}

                <h4 className="col-12">
                  {rooms.room_name ? (
                    <div className="font-weight-bold text-white">
                      {rooms.room_name}
                    </div>
                  ) : (
                    <div
                      className="font-weight-bold"
                      style={{ height: "1.5rem" }}
                    ></div>
                  )}
                  {/* <h4 className="col-12 text-white font-weight-bold mt-1">
                  {table?.LocationSessions[0]?.session_status === "started" ? (
    table?.LocationSessions[0]?.order_from
  ) : null}
                  </h4> */}
                  <h4 className="col-12 text-white font-weight-bold mt-1">
                    {rooms?.LocationSessions[0]?.session_status === "started"
                      ? this.getOrderFromDisplayName(
                          rooms?.LocationSessions[0]?.order_from
                        )
                      : null}
                  </h4>

                  {/* <div className="font-weight-bold">{table.table_name} </div> */}
                  {rooms.is_room_available ? (
                    <span>
                      {" "}
                      <button
                        type="submit"
                        className="cart btn btn-success mt-1 col-6 "
                        style={{ cursor: "not-allowed" }}
                      >
                        {t("free")}{" "}
                      </button>
                    </span>
                  ) : (
                    <button
                      onClick={() => {
                        this.props.closeLocationSession(
                          rooms?.LocationSessions[0]?.session_id
                        );
                        // this.sessionEndDate();
                      }}
                      type="submit"
                      className="cart btn btn-warning mt-1"
                    >
                      {t("close-session")}{" "}
                    </button>
                  )}
                </h4>
              </div>

              <CardBody className="text-center">
                <div className="item-wrapper">
                  <div className="product-price">
                    <h4 className="item-price">
                      {rooms.is_room_available ? (
                        <span className=" font-weight-bold success">
                          <br></br>
                        </span>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            className=" text-white mr-1"
                            icon={faStopwatch}
                            style={{ fontSize: "20px" }}
                          />
                          <span className=" font-weight-bold warning">
                            <span className=" font-weight-bold warning"></span>
                            {sessionTimeCorrect}
                            {/* {sessionTime.toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })} */}
                          </span>
                        </>
                      )}
                    </h4>

                    {/* <h4 className="item-price">
                      <FontAwesomeIcon
                        className=" text-white mr-1"
                        icon={faStopwatch}
                        style={{ fontSize: '20px' }}
                      />
                      {table.is_table_available ? (
                        <span className=" font-weight-bold success">
                          Not Started
                        </span>
                      ) : (
                        <span className=" font-weight-bold warning">
                          {sessionTime.toLocaleTimeString('en-US', {
                            // en-US can be set to 'default' to use user's browser settings
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </span>
                      )}
                    </h4> */}
                  </div>
                </div>
              </CardBody>
            </div>
          </Card>
          {/* )} */}
        </div>
      );
    });
    // Filter out null values (tables with table_status false)
    return renderTables;
  };

  // getSessionTime = (time_diff) => {
  //   let cDate = new Date();
  //   console.log("CDATE", cDate);
  //   let sDate = new Date(time_diff);
  //   console.log("sdate", sDate);
  //   const diffTime = Math.abs(cDate - sDate);
  //   const diffMinutes = Math.ceil(diffTime / (1000 * 60));

  //   console.log("DIFFERENCE TIME", diffMinutes);
  //   return diffMinutes + " Minutes";
  // };
  getSessionTime = (time_diff) => {
    let cDate = new Date();
    console.log("CDATE", cDate);
    let sDate = new Date(time_diff);
    console.log("sdate", sDate);
    const diffTime = Math.abs(cDate - sDate);
    let diffMinutes = Math.ceil(diffTime / (1000 * 60));

    console.log("DIFFERENCE TIME", diffMinutes);

    let result;
    if (diffMinutes > 60) {
      const diffHours = Math.floor(diffMinutes / 60);
      result = `${diffHours} Hrs`;
    } else {
      result = `${diffMinutes} Minutes`;
    }

    return result;
  };

  // getSessionTime = (time_diff) => {
  //   let cDate = new Date();
  //   console.log("CDATE", cDate);
  //   let sDate = new Date(time_diff);
  //   console.log("sdate", sDate);
  //   const diffTime = Math.abs(cDate - sDate);
  //   let diffMinutes = Math.ceil(diffTime / (1000 * 60));

  //   console.log("DIFFERENCE TIME", diffMinutes);

  //   if (diffMinutes > 60) {
  //     diffMinutes = Math.round(diffMinutes / 60) * 60;
  //   }
  //   let result = diffMinutes + " Minutes";
  //   if (diffMinutes > 1000) {
  //     result = Math.round(diffMinutes / 1000) + "K Minutes";
  //   }

  //   return result;
  // };
  formatAmount = (amount) => {
    if (amount >= 1000) {
      if (amount >= 1000000 && amount < 1000000000) {
        return (amount / 1000000).toFixed(1) + "M";
      } else if (amount >= 1000000000) {
        return (amount / 1000000000).toFixed(1) + "B";
      } else {
        return (amount / 1000).toFixed(1) + "K";
      }
    } else {
      return amount.toFixed(2);
    }
  };

  // sessionEndDate = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const day = String(today.getDate()).padStart(2, "0");

  //   console.log(year, month, day);
  //   return `${year}-${month}-${day}`;
  // };

  render() {
    const { rooms } = this.props;
    const { t } = this.props;

    console.log(this.props);

    return (
      <div className="col-12">
        <Row>
          <Col sm="12">
            <div id="ecommerce-products">
              <Row>{this.renderRoomsData(rooms)}</Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation("global")(ListSessionRoomsStatus);

// export default ListSessionTablesStatus;
