import React from "react";
import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import {
  Button,
  Card,
  Form,
  FormControl,
  Modal,
  ModalBody,
  ModalTitle,
} from "react-bootstrap";
import CheckBoxesVuexy from "../@vuexy/checkbox/CheckboxesVuexy";
import RadioVuexy from "../@vuexy/radio/RadioVuexy";
// import mixpanel from "mixpanel-browser";

const MenuOptionsModal = ({
  show,
  close,
  onClose,
  closeModal,
  itemName,
  receiveCartItems,
}) => {
  console.log("ITEMNAME", itemName);
  const [quantity, setQuantity] = useState(1);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedRadioValues, setSelectedRadioValues] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectValueCounts, setSelectValueCounts] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [specialInstruction, setSpecialInstruction] = useState("");

  const [selectedRadioCartItem, setSelectedRadioCartItem] = useState([]);
  const [selectedCheckboxCartItem, setSelectedCheckboxCartItem] = useState([]);
  const [selectedSelectCartItem, setSelectedSelectCartItem] = useState([]);

  const [cartItemsDataList, setCartItemsDataList] = useState("");

  const [requiredFieldError, setRequiredFieldError] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMsg, setOpenSnackBarMsg] = useState("");

  const handleRadioChange = (index, value) => {
    console.log(index, value, "handle");

    setSelectedRadioValues((prevValues) => {
      console.log("Previous Values:", prevValues);
      console.log("Previous Value for Index:", prevValues[index]);

      const updatedValues = [...prevValues];

      // If the clicked value is already selected, unselect it
      if (updatedValues[index] === value) {
        updatedValues[index] = "";
      } else {
        // Otherwise, select the clicked value
        updatedValues[index] = value;
      }

      console.log("Updated Values:", updatedValues);

      return updatedValues;
    });
  };

  const resetValues = () => {
    setQuantity(1);
    setSelectedValues([]);
    setSelectedRadioValues([]);
    setSelectedCheckboxes([]);
    setSelectValueCounts([]);
    setShowMore();
    setTotalAmount(0);
    setTotalTax(0);
    setSubTotal(0);
    setSelectedRadioCartItem([]);
    setSelectedCheckboxCartItem([]);
    setSelectedSelectCartItem([]);
    setRequiredFieldError("");
    setOpenSnackBar(false);
    setOpenSnackBarMsg("");
    closeModal();
  };
  const handleClose = () => {
    resetValues();
    closeModal();
  };

  useEffect(() => {
    setCartItemsDataList();
  }, []);

  useEffect(() => {
    let baseprice = 0;
    let subtotal = 0;
    let totalamount = 0;
    let totaltax = 0;
    let menutax = 0;
    if (selectedRadioCartItem.length > 0) {
      selectedRadioCartItem.map((item) => {
        item.Option_Values.map((option) => {
          if (item.base_price == 1) {
            baseprice = 1;
            subtotal +=
              parseFloat(option.price) * option.optionCount * quantity;
            totalamount +=
              parseFloat(option.price) * option.optionCount * quantity;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
              totalamount +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
            }
          } else {
            subtotal += parseFloat(option.price) * option.optionCount;
            totalamount += parseFloat(option.price) * option.optionCount;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) * option.optionCount;
              totalamount +=
                parseFloat(option.order_item_tax) * option.optionCount;
            }
          }
          console.log("TOTAL: 0 " + totalamount);
          console.log("SubTotal: 0 " + subtotal);
          console.log("totaltax: 0 " + totaltax);
        });
      });
    }

    if (selectedCheckboxCartItem.length > 0) {
      selectedCheckboxCartItem.map((item) => {
        item.Option_Values.map((option) => {
          if (item.base_price == 1) {
            baseprice = 1;
            subtotal +=
              parseFloat(option.price) * option.optionCount * quantity;
            totalamount +=
              parseFloat(option.price) * option.optionCount * quantity;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
              totalamount +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
            }
          } else {
            subtotal += parseFloat(option.price) * option.optionCount;
            totalamount += parseFloat(option.price) * option.optionCount;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) * option.optionCount;
              totalamount +=
                parseFloat(option.order_item_tax) * option.optionCount;
            }
          }
        });
      });
    }

    if (selectedSelectCartItem.length > 0) {
      selectedSelectCartItem.map((item) => {
        item.Option_Values.map((option) => {
          if (item.base_price == 1) {
            baseprice = 1;
            subtotal +=
              parseFloat(option.price) * option.optionCount * quantity;
            totalamount +=
              parseFloat(option.price) * option.optionCount * quantity;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
              totalamount +=
                parseFloat(option.order_item_tax) *
                option.optionCount *
                quantity;
            }
          } else {
            subtotal += parseFloat(option.price) * option.optionCount;
            totalamount += parseFloat(option.price) * option.optionCount;
            if (option.order_item_tax > 0) {
              totaltax +=
                parseFloat(option.order_item_tax) * option.optionCount;
              totalamount +=
                parseFloat(option.order_item_tax) * option.optionCount;
            }
          }
        });
      });
    }

    console.log("TOTAL: m " + totalamount);
    console.log("subtotal: m " + subtotal);
    console.log("totaltax: m " + totaltax);
    if (baseprice == 0) {
      subtotal += parseFloat(itemName.menu_price) * quantity;
      totalamount += parseFloat(itemName.menu_price) * quantity;
      menutax = (itemName.menu_price / 100) * itemName.menu_tax;
      menutax = parseFloat(menutax).toFixed(2);
      totalamount += parseFloat(menutax);
    }
    totaltax += parseFloat(menutax);

    console.log("TOTAL: 1 " + totalamount);
    console.log("subtotal: 1 " + subtotal);
    console.log("totaltax: 1 " + totaltax);
    setTotalAmount(totalamount.toFixed(2));
    setSubTotal(subtotal.toFixed(2));
    setTotalTax(totaltax.toFixed(2));
  }, [
    itemName,
    totalAmount,
    subTotal,
    totalTax,
    selectedSelectCartItem,

    quantity,
    selectedCheckboxCartItem,
    selectedRadioCartItem,
  ]);

  const handleSelectValueSelection = (valueId, menuOption, action) => {
    const updatedValues = [...selectedValues];
    const updatedCounts = { ...selectValueCounts };
    console.log(updatedCounts, updatedValues, "selected");
    // if (updatedValues.includes(valueId)) {
    //   // Value is already selected, so increment the count by 1
    //   updatedCounts[valueId] = (updatedCounts[valueId] || 0) + 1;
    // } else {
    //   // Value is not selected, so add it and set the count to 1
    //   updatedValues.push(valueId);
    //   updatedCounts[valueId] = 1;
    // }
    // if (updatedValues.includes(valueId)) {
    //   if (action === "increment") {
    //     updatedCounts[valueId] = (updatedCounts[valueId] || 0) + 1;
    //   } else if (action === "decrement") {
    //     updatedCounts[valueId] = Math.max((updatedCounts[valueId] || 0) - 1, 0);
    //   }
    // } else {
    //   updatedValues.push(valueId);
    //   updatedCounts[valueId] = 1;
    // }
    if (updatedValues.includes(valueId)) {
      if (action === "increment") {
        updatedCounts[valueId] = (updatedCounts[valueId] || 0) + 1; // Increment count by 1
      } else if (action === "decrement") {
        updatedCounts[valueId] = Math.max((updatedCounts[valueId] || 0) - 1, 0); // Decrement count by 1, but ensure it doesn't go below 0
      }
    } else {
      updatedValues.push(valueId);
      if (action === "increment") {
        updatedCounts[valueId] = menuOption.min;
      } else if (action === "decrement") {
        console.error("Attempted to decrement a value that doesn't exist.");
      }
    }

    if (menuOption.max != 0 && updatedCounts[valueId] > menuOption.max) {
      setOpenSnackBar(true);
      setOpenSnackBarMsg("you-have-selected-maximum-option-quantity");
      return;
    }

    let radioTypeItm = {
      option_id: menuOption.option_id,
      display_type: "select",
      base_price: menuOption.base_price,
      option_name: menuOption.Option.option_name,
      Option_Values: [],
    };

    if (updatedValues.length > 0) {
      updatedValues.map((item) => {
        menuOption.MenuOptionValues.map((option) => {
          if (option.option_value_id == item) {
            let price = option.new_price > 0 ? option.new_price : 0;
            let itemtax =
              menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
            if (updatedCounts[item] > 0) {
              radioTypeItm.Option_Values.push({
                optionCount: updatedCounts[item],
                value: option.OptionValue.value,
                option_value_id: option.option_value_id,
                price: price,
                order_item_tax: itemtax.toFixed(2),
                order_item_tax_percentage:
                  menuOption.item_tax > 0 ? menuOption.item_tax : 0,
                menu_option_type: menuOption.option_menu_type,
              });
            }
          }
        });
      });
    }

    let arraySelectedSelectCartItem = [...selectedSelectCartItem];
    let match = false;
    arraySelectedSelectCartItem.map((item, index) => {
      if (item.option_id == radioTypeItm.option_id) {
        match = true;
        arraySelectedSelectCartItem[index] = radioTypeItm;
      }
    });
    if (!match) {
      arraySelectedSelectCartItem.push(radioTypeItm);
    }

    // for removing empty option values
    let selectedItemsArray = [];
    arraySelectedSelectCartItem.map((item) => {
      if (item.Option_Values.length > 0) {
        selectedItemsArray.push(item);
      }
    });

    setSelectedSelectCartItem(selectedItemsArray);
    setSelectedValues(updatedValues);
    setSelectValueCounts(updatedCounts);
  };

  const handleRadioValueSelection = (index, valueId, menuOption) => {
    let selectedOption = null;
    menuOption.MenuOptionValues.map((option) => {
      if (option.option_value_id == valueId) {
        selectedOption = option;
      }
    });
    let price = selectedOption.new_price > 0 ? selectedOption.new_price : 0;
    let itemtax =
      menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
    let radioTypeItm = {
      option_id: selectedOption.option_id,
      display_type: "radio",
      base_price: menuOption.base_price,
      option_name: menuOption.Option.option_name,
      Option_Values: [
        {
          optionCount: 1,
          value: selectedOption.OptionValue.value,
          option_value_id: selectedOption.option_value_id,
          price: price,
          order_item_tax: itemtax.toFixed(2),
          order_item_tax_percentage:
            menuOption.item_tax > 0 ? menuOption.item_tax : 0,
          menu_option_type: menuOption.option_menu_type,
        },
      ],
    };
    let arraySelectedRadioCartItem = [...selectedRadioCartItem];
    let match = false;
    if (selectedRadioValues.length > 0) {
      arraySelectedRadioCartItem.map((item, index) => {
        if (item.option_id == radioTypeItm.option_id) {
          match = true;
          arraySelectedRadioCartItem[index] = radioTypeItm;
        }
      });
      if (!match) {
        arraySelectedRadioCartItem.push(radioTypeItm);
      }
    } else {
      arraySelectedRadioCartItem.push(radioTypeItm);
    }
    let arrayRadioItems = [...selectedRadioValues];
    arrayRadioItems[index] = valueId;
    setSelectedRadioValues(arrayRadioItems);
    setSelectedRadioCartItem(arraySelectedRadioCartItem);
  };

  const handleCheckboxValueSelection = (valueId, menuOption) => {
    let preSelectedCheckboxes = [];
    if (selectedCheckboxes.includes(valueId)) {
      preSelectedCheckboxes = selectedCheckboxes.filter((id) => id !== valueId);
    } else {
      preSelectedCheckboxes = [...selectedCheckboxes, valueId];
    }

    let radioTypeItm = {
      option_id: menuOption.option_id,
      display_type: "checbox",
      base_price: menuOption.base_price,
      option_name: menuOption.Option.option_name,
      Option_Values: [],
    };
    if (preSelectedCheckboxes.length > 0) {
      preSelectedCheckboxes.map((item) => {
        menuOption.MenuOptionValues.map((option) => {
          if (option.option_value_id == item) {
            let price = option.new_price > 0 ? option.new_price : 0;
            let itemtax =
              menuOption.item_tax > 0 ? (price / 100) * menuOption.item_tax : 0;
            radioTypeItm.Option_Values.push({
              optionCount: 1,
              value: option.OptionValue.value,
              option_value_id: option.option_value_id,
              price: price,
              order_item_tax: itemtax.toFixed(2),
              order_item_tax_percentage:
                menuOption.item_tax > 0 ? menuOption.item_tax : 0,
              menu_option_type: menuOption.option_menu_type,
            });
          }
        });
      });
    }

    let arraySelectedChecboxCartItem = [...selectedCheckboxCartItem];
    let match = false;
    let maxSelectedValuesReached = false;
    arraySelectedChecboxCartItem.map((item, index) => {
      if (item.option_id == radioTypeItm.option_id) {
        match = true;
        arraySelectedChecboxCartItem[index] = radioTypeItm;
        if (
          menuOption.max != 0 &&
          arraySelectedChecboxCartItem[index].Option_Values.length >
            menuOption.max
        ) {
          maxSelectedValuesReached = true;
        }
      }
    });
    if (!match) {
      arraySelectedChecboxCartItem.push(radioTypeItm);
    }

    // for removing empty option values
    let selectedItemsArray = [];
    arraySelectedChecboxCartItem.map((item) => {
      if (item.Option_Values.length > 0) {
        selectedItemsArray.push(item);
      }
    });

    if (maxSelectedValuesReached) {
      setOpenSnackBar(true);
      setOpenSnackBarMsg("you-have-selected-maximum-options");
      return;
    }

    setSelectedCheckboxes((prevCheckboxes) => {
      if (prevCheckboxes.includes(valueId)) {
        return prevCheckboxes.filter((id) => id !== valueId);
      } else {
        return [...prevCheckboxes, valueId];
      }
    });
    setSelectedCheckboxCartItem(selectedItemsArray);
  };

  const setCartValues = () => {
    // let baseprice = 0;
    // let subtotal = 0;
    // let totalamount = 0;
    // let totaltax = 0;
    // if (selectedRadioCartItem.length > 0) {
    //   selectedRadioCartItem.map((item) => {
    //     item.Option_Values.map((option) => {
    //       if (item.base_price == 1) {
    //         baseprice = 1;
    //         subtotal +=
    //           parseFloat(option.price) * option.optionCount * quantity;
    //         totalamount +=
    //           parseFloat(option.price) * option.optionCount * quantity;
    //         if (option.order_item_tax > 0) {
    //           totaltax +=
    //             parseFloat(option.order_item_tax) *
    //             option.optionCount *
    //             quantity;
    //           totalamount +=
    //             parseFloat(option.order_item_tax) *
    //             option.optionCount *
    //             quantity;
    //         }
    //       } else {
    //         subtotal += parseFloat(option.price) * option.optionCount;
    //         totalamount += parseFloat(option.price) * option.optionCount;
    //         if (option.order_item_tax > 0) {
    //           totaltax +=
    //             parseFloat(option.order_item_tax) * option.optionCount;
    //           totalamount +=
    //             parseFloat(option.order_item_tax) * option.optionCount;
    //         }
    //       }
    //       console.log("TOTAL: 0 " + totalamount)
    //       console.log("SubTotal: 0 " + subtotal)
    //       console.log("totaltax: 0 " + totaltax)
    //     });
    //   });
    // }
    // if (selectedCheckboxCartItem.length > 0) {
    //   selectedCheckboxCartItem.map((item) => {
    //     item.Option_Values.map((option) => {
    //       if (item.base_price == 1) {
    //         baseprice = 1;
    //         subtotal +=
    //           parseFloat(option.price) * option.optionCount * quantity;
    //         totalamount +=
    //           parseFloat(option.price) * option.optionCount * quantity;
    //         if (option.order_item_tax > 0) {
    //           totaltax +=
    //             parseFloat(option.order_item_tax) *
    //             option.optionCount *
    //             quantity;
    //           totalamount +=
    //             parseFloat(option.order_item_tax) *
    //             option.optionCount *
    //             quantity;
    //         }
    //       } else {
    //         subtotal += parseFloat(option.price) * option.optionCount;
    //         totalamount += parseFloat(option.price) * option.optionCount;
    //         if (option.order_item_tax > 0) {
    //           totaltax +=
    //             parseFloat(option.order_item_tax) * option.optionCount;
    //           totalamount +=
    //             parseFloat(option.order_item_tax) * option.optionCount;
    //         }
    //       }
    //     });
    //   });
    // }
    // if (selectedSelectCartItem.length > 0) {
    //   selectedSelectCartItem.map((item) => {
    //     item.Option_Values.map((option) => {
    //       if (item.base_price == 1) {
    //         baseprice = 1;
    //         subtotal +=
    //           parseFloat(option.price) * option.optionCount * quantity;
    //         totalamount +=
    //           parseFloat(option.price) * option.optionCount * quantity;
    //         if (option.order_item_tax > 0) {
    //           totaltax +=
    //             parseFloat(option.order_item_tax) *
    //             option.optionCount *
    //             quantity;
    //           totalamount +=
    //             parseFloat(option.order_item_tax) *
    //             option.optionCount *
    //             quantity;
    //         }
    //       } else {
    //         subtotal += parseFloat(option.price) * option.optionCount;
    //         totalamount += parseFloat(option.price) * option.optionCount;
    //         if (option.order_item_tax > 0) {
    //           totaltax +=
    //             parseFloat(option.order_item_tax) * option.optionCount;
    //           totalamount +=
    //             parseFloat(option.order_item_tax) * option.optionCount;
    //         }
    //       }
    //     });
    //   });
    // }
    // console.log("TOTAL: m " + totalamount)
    // console.log("subtotal: m " + subtotal)
    // console.log("totaltax: m " + totaltax)
    // console.log("itemName", itemName);
    // let menutax = 0;
    // if (baseprice == 0) {
    //   subtotal += parseFloat(itemName.menu_price) * quantity;
    //   totalamount += parseFloat(itemName.menu_price) * quantity;
    //   menutax = (itemName.menu_price / 100) * itemName.menu_tax * quantity;
    //   menutax = parseFloat(menutax).toFixed(2);
    //   totalamount += parseFloat(menutax);
    // }
    // totaltax += parseFloat(menutax);
    // console.log("TOTAL: 1 " + totalamount)
    // console.log("subtotal: 1 " + subtotal)
    // console.log("totaltax: 1 " + totaltax)
    // setTotalAmount(totalamount.toFixed(2));
    // setSubTotal(subtotal.toFixed(2));
    // setTotalTax(totaltax.toFixed(2));
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const addToCart = () => {
    console.log("add to cart");
    // mixpanel.track("Add to cart", {
    //   name: "jay",
    //   class: "5th",
    //   age: 14,
    // });

    let selectedValuesForCart = [];
    selectedRadioCartItem.map((item) => {
      selectedValuesForCart.push(item.option_id);
    });

    selectedCheckboxCartItem.map((item) => {
      selectedValuesForCart.push(item.option_id);
    });

    selectedSelectCartItem.map((item) => {
      selectedValuesForCart.push(item.option_id);
    });

    for (let i = 0; i < itemName.MenuOptions.length; i++) {
      let requiredFound = false;
      if (selectedValuesForCart.length == 0) {
        if (itemName.MenuOptions[i].required != 1) {
          requiredFound = true;
        }
      } else {
        for (let j = 0; j < selectedValuesForCart.length; j++) {
          if (
            itemName.MenuOptions[i].required == 1 &&
            itemName.MenuOptions[i].option_id == selectedValuesForCart[j]
          ) {
            requiredFound = true;
          } else if (itemName.MenuOptions[i].required != 1) {
            requiredFound = true;
          }
        }
      }

      if (!requiredFound) {
        setOpenSnackBar(true);
        setOpenSnackBarMsg("please-select-all-required-options");
        return;
      }
    }

    //   for (let i = 0; i < itemName.MenuOptions.length; i++) {
    //     let minFound = false;

    //     console.log(itemName.MenuOptions[i].Option.display_type , "itemName.MenuOptions");
    //     console.log(selectedSelectCartItem, "selectedSelectCartItem");
    //     console.log(selectedSelectCartItem[0]?.Option_Values[0]?.hasOwnProperty('optionCount'), "selectedSelectCartItem");
    // console.log(itemName.MenuOptions[i].min,"itemName.MenuOptions[i].min")
    //     if (selectedSelectCartItem.length === 0) {
    //         if (itemName.MenuOptions[i].min !== 1) {
    //             minFound = true;
    //         }
    //     } else {
    //         for (let j = 0; j < selectedSelectCartItem.length; j++) {
    //             const selectedItem = selectedSelectCartItem[j];
    //             console.log(selectedSelectCartItem[j].display_type,"type");
    //             const hasOptionCount = selectedItem.Option_Values?.[0]?.optionCount;
    //             console.log(hasOptionCount)

    //             if (hasOptionCount <= itemName.MenuOptions[i].min&& itemName.MenuOptions[j].option_id === selectedItem.option_id   ) {
    //                 minFound = true;
    //             }
    //             else{
    //               minFound =false;
    //             }
    //         }
    //     }

    //     if (!minFound) {
    //         setOpenSnackBar(true);
    //         setOpenSnackBarMsg(t('please-select-minium-options'));
    //         return;
    //     }
    // }

    // console.log("CART ITEMS FINAL", cartItemsDataList);
    for (let i = 0; i < itemName.MenuOptions.length; i++) {
      let minFound = false;
      console.log(itemName.MenuOptions[i], "itemName.MenuOptions");
      let errorMessage = [];

      // Check if the display_type is 'select'
      if (itemName.MenuOptions[i].Option.display_type === "select") {
        if (selectedSelectCartItem.length === 0) {
          if (itemName.MenuOptions[i].min !== 1) {
            minFound = false;
          }
        } else {
          for (let j = 0; j < selectedSelectCartItem.length; j++) {
            const selectedItem = selectedSelectCartItem[j];
            const optionValues = selectedItem.Option_Values?.map(
              (option) => option.value
            );

            console.log(selectedItem, "selected");
            const hasOptionCount = selectedItem.Option_Values?.map(
              (option) => option.optionCount
            );
            const minOptionCount = Math.min(...hasOptionCount);
            if (
              minOptionCount < itemName.MenuOptions[i].min &&
              itemName.MenuOptions[i].option_id === selectedItem.option_id
            ) {
              minFound = true;
              // errorMessage = `${optionValues} (${minOptionCount}) `;
              errorMessage.push({
                value: optionValues,
                // optionCount: minOptionCount,
              });
              // console.log(errorMessage.map((item) => console.log(item.value)));
            } else {
              minFound = false;
            }
          }
        }
        errorMessage.forEach((item) => {
          item.value.forEach((value) => {
            console.log(value);
          });
        });
        if (minFound) {
          const errorMessageString = errorMessage
            .map((item) => item.value.join(", "))
            .join("; ");
          // const nameError = itemName.MenuOptions[i].MenuOptionValues?.map(
          //   (item) => item.OptionValue.value
          // );
          // console.log(nameError, "nameError");
          setOpenSnackBar(true);
          setOpenSnackBarMsg(
            `Minimum-Quantity-of these Varients- ${errorMessageString} must be ${itemName.MenuOptions[i].min}`
          );
          return;
        }
      }
    }

    let cartItems = JSON.stringify(cartItemsDataList);
    let menuItemsOptions = [];
    if (selectedRadioCartItem.length > 0) {
      menuItemsOptions = [...menuItemsOptions, ...selectedRadioCartItem];
    }

    if (selectedCheckboxCartItem.length > 0) {
      menuItemsOptions = [...menuItemsOptions, ...selectedCheckboxCartItem];
    }

    if (selectedSelectCartItem.length > 0) {
      menuItemsOptions = [...menuItemsOptions, ...selectedSelectCartItem];
    }

    let menuTax = (itemName.menu_price / 100) * itemName.menu_tax * quantity;
    if (cartItems?.length === 0) {
      cartItems.push({
        menu_id: itemName.menu_id,
        menu_name: itemName.menu_name,
        MenuOptions: menuItemsOptions,
        menu_type: itemName.menu_type,
        itemCount: quantity,
        menu_price: itemName.menu_price,
        comment: specialInstruction,
        order_menu_tax: parseFloat(menuTax.toFixed(2)),
        menu_tax_percentage: itemName.menu_tax,
        menu_photo: itemName.menu_photo,
      });
      setCartItemsDataList(cartItems);
      receiveCartItems(cartItems);
      console.log("CART ITEMS FINAL", cartItems);
    } else {
      updateCartItemList(cartItems, menuItemsOptions, menuTax);
    }
    const payload = {
      subTotal: subTotal,
      totalTax: totalTax,
      totalAmount: totalAmount,
    };

    console.log("PAYLOAD", payload);

    // mixpanel.track("Add to cart", {
    //   subTotal: subTotal,
    //   totalTax: totalTax,
    //   totalAmount: totalAmount,
    // });

    // Dispatch the updateCartTotal action with the calculated values

    resetValues();
  };
  // Call setCartValues whenever the cartData or quantity changes
  // useEffect(() => {
  //   setCartValues();
  // }, [cart, quantity]);

  const updateCartItemList = async (cartItems, menuItemsOptions, menuTax) => {
    let found = false;
    let itemQuantity = 0;
    // let newMenuItemOptionsArray = [...menuItemsOptions];
    // let newMenuOptionsCloneGrid = await cloneGrid(menuItemsOptions);
    // let newMenuItemOptionsArray = [...newMenuOptionsCloneGrid];
    let newMenuItemOptionsArray = JSON.parse(JSON.stringify(menuItemsOptions));

    for (let i = 0; i < cartItems?.length; i++) {
      if (cartItems[i].menu_id == itemName.menu_id) {
        if (cartItems[i].comment == specialInstruction) {
          found = true;
        } else {
          found = false;
          break;
        }

        if (
          newMenuItemOptionsArray.length === cartItems[i].MenuOptions.length
        ) {
          found = true;
          for (let j = 0; j < cartItems[i].MenuOptions.length; j++) {
            //0 //6
            for (let k = 0; k < newMenuItemOptionsArray.length; k++) {
              //6 //6
              if (
                cartItems[i].MenuOptions[j].option_id ==
                newMenuItemOptionsArray[k].option_id
              ) {
                found = true;
                if (
                  cartItems[i].MenuOptions[j].Option_Values.length ===
                  newMenuItemOptionsArray[k].Option_Values.length
                ) {
                  found = true;
                  if (cartItems[i].MenuOptions[j].Option_Values.length > 0) {
                    for (
                      let l = 0;
                      l < cartItems[i].MenuOptions[j].Option_Values.length;
                      l++
                    ) {
                      for (
                        let m = 0;
                        m < newMenuItemOptionsArray[k].Option_Values.length;
                        m++
                      ) {
                        if (
                          cartItems[i].MenuOptions[j].Option_Values[l]
                            .option_value_id ==
                          newMenuItemOptionsArray[k].Option_Values[m]
                            .option_value_id
                        ) {
                          found = true;
                          newMenuItemOptionsArray[k].Option_Values[
                            m
                          ].optionCount += parseInt(
                            cartItems[i].MenuOptions[j].Option_Values[l]
                              .optionCount
                          );
                          newMenuItemOptionsArray[k].Option_Values[
                            m
                          ].order_item_tax =
                            parseFloat(
                              newMenuItemOptionsArray[k].Option_Values[m]
                                .order_item_tax
                            ) +
                            parseFloat(
                              cartItems[i].MenuOptions[j].Option_Values[l]
                                .order_item_tax
                            ) *
                              parseInt(
                                cartItems[i].MenuOptions[j].Option_Values[l]
                                  .optionCount
                              );
                        } else {
                          found = false;
                        }
                      }
                      if (found) {
                        break;
                      }
                    }
                  }
                } else {
                  found = false;
                }
              } else {
                found = false;
              }

              if (found) {
                break;
              }
            }
            if (!found) {
              break;
            }
          }

          if (found) {
            itemQuantity =
              parseInt(quantity) + parseInt(cartItems[i].itemCount);
            cartItems[i].MenuOptions = newMenuItemOptionsArray;
            cartItems[i].itemCount = itemQuantity;
            cartItems[i].order_menu_tax =
              parseFloat(cartItems[i].order_menu_tax) * itemQuantity;
            break;
          }
        } else {
          found = false;
        }
      }
    }

    if (!found) {
      cartItems = {
        menu_id: itemName.menu_id,
        menu_name: itemName.menu_name,
        MenuOptions: menuItemsOptions,
        menu_type: itemName.menu_type,
        itemCount: quantity,
        menu_price: itemName.menu_price,
        comment: specialInstruction,
        order_menu_tax: menuTax.toFixed(2) * quantity,
        menu_tax_percentage: itemName.menu_tax,
        menu_photo: itemName.menu_photo,
      };
    }
    console.log("CARTITEMS", cartItems);
    setCartItemsDataList(cartItems);
    receiveCartItems(cartItems);
  };

  async function cloneGrid(grid) {
    const newGrid = [...grid];
    let newArray = [];
    // newGrid.forEach(
    //   (row, rowIndex) => (
    //     ((newArray[rowIndex] = { ...row }), (newGrid[rowIndex] = { ...row })),
    //     row.Option_Values.forEach((a, index) => {
    //       newGrid[rowIndex].Option_Values[index] = { ...a };
    //       newArray[rowIndex].Option_Values[index] = { ...a };
    //     })
    //   )
    // );

    for (let i = 0; i < grid.length; i++) {
      newArray[i] = grid[i].slice();
    }
    return newArray;
  }

  // useEffect(() => {
  //   setCartValues();
  // }, [selectedSelectCartItem]);

  // useEffect(() => {
  //   setCartValues();
  // }, [selectedCheckboxCartItem]);

  // useEffect(() => {
  //   setCartValues();
  // }, [selectedRadioCartItem]);

  // useEffect(() => {
  //   setCartValues();
  // }, [quantity]);

  // const calculateTotalPrice = (menuPrice, taxPercent) => {
  //   const itemPrice = menuPrice || 0;
  //   const tax = taxPercent || 0;
  //   return itemPrice + (itemPrice * tax) / 100;
  // };
  const calculateTotalPrice = (menuPrice, taxPercent) => {
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    // Check if taxPercent is greater than 0 before multiplying
    const totalPrice =
      tax > 0 ? itemPrice + (itemPrice * tax) / 100 : itemPrice;

    return totalPrice;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <ModalBody className="custom-scrollbar">
          <div className="container">
            <div item xs={12} sm={12} md={12}></div>
            <ModalTitle>
              {" "}
              <p>{itemName.menu_name}</p>
              <p>{itemName.menu_price}</p>
              <div></div>
            </ModalTitle>

            <div item xs={12} sm={12} md={12}>
              <div>
                <p>{itemName.menu_description}</p>

                {itemName.MenuOptions.map(
                  (menuOption, index) => (
                    console.log("menuOptionTAX", menuOption.item_tax),
                    (
                      <div key={index}>
                        {menuOption.MenuOptionValues.some(
                          (optionValue) =>
                            optionValue?.OptionValue?.status === 1
                        ) && (
                          <div mt={2}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "#e9dfd0",
                                borderRadius: 4,
                                padding: "10px",
                              }}
                            >
                              <p>{menuOption.Option.option_name}</p>
                              {menuOption.required ? (
                                <p style={{ marginLeft: "auto" }}>
                                  <small>required</small>
                                </p>
                              ) : null}
                            </div>
                            {menuOption.Option.display_type === "select" ||
                            (menuOption.Option.display_type === "checkbox" &&
                              (menuOption.min !== 0 ||
                                menuOption.max !== 0)) ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {menuOption.Option.display_type === "select" ? (
                                  <small>
                                    {"you-need-to-select-maximum"}{" "}
                                    {menuOption.max}{" "}
                                    {"quantity-from-each-option"}{" "}
                                  </small>
                                ) : (
                                  <small>
                                    {"you-need-to-select-maximum-0"}{" "}
                                    {menuOption.max} {"options"}
                                  </small>
                                )}
                              </div>
                            ) : null}
                          </div>
                        )}

                        {/* <Box mt={2}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                background: "#e9dfd0",
                                borderRadius: 4,
                                padding: "10px",
                              }}
                            >
                              <Typography variant="body1" fontWeight="bold">
                                {menuOption.Option.option_name}
                              </Typography>
                              {menuOption.required ? (
                                <Typography
                                  variant="body1"
                                  style={{ marginLeft: "auto" }}
                                >
                                  <small>required</small>
                                </Typography>
                              ) : null}
                            </div>
                            {(menuOption.Option.display_type === "select" ||
                              menuOption.Option.display_type === "checkbox") &&
                            (menuOption.min != 0 || menuOption.max != 0) ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {menuOption.Option.display_type === "select" ? (
                                  <small>
                                    {t("you-need-to-select-maximum")}{" "}
                                    {menuOption.max}{" "}
                                    {t("quantity-from-each-option")}{" "}
                                  </small>
                                ) : (
                                  <small>
                                    {t("you-need-to-select-maximum-0")}{" "}
                                    {menuOption.max} {t("options")}
                                  </small>
                                )}
                              </div>
                            ) : null}
                          </Box> */}
                        <div mt={2}>
                          {menuOption.Option.display_type === "select" && (
                            <Form>
                              {menuOption.MenuOptionValues.map(
                                (menuOptionValue, idx) => (
                                  <>
                                    {/* {!showMore &&
                                      idx >= MAX_OPTIONS ? null : (
                                        // Render only 5 options and show rest on View More button click */}
                                    <>
                                      {menuOptionValue?.OptionValue?.status ==
                                        1 && (
                                        <Card
                                          style={{
                                            padding: "10px",
                                            margin: "10px",
                                          }}
                                        >
                                          <div
                                            key={idx}
                                            style={{
                                              // background: "red",
                                              display: "flex",
                                              justifyContent: "space-between", // Align items to the left and right
                                              alignItems: "center", // Center items vertically
                                              // marginBottom: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                // background: "red",
                                                display: "flex",
                                                justifyContent: "space-between", // Align items to the left and right
                                                alignItems: "center", // Center items vertically
                                                // marginBottom: "8px",
                                              }}
                                            >
                                              <div sx={{ ml: "10px" }}>
                                                {/* <Typography
         style={{
           marginRight: "8px",
           marginBottom: "8px",
           fontWeight:
             selectedValues.includes(
               menuOptionValue.option_value_id
             )
               ? "bold"
               : "normal",
           flexShrink: 0,
           width: "40%", // Adjust the width to take up less space
           fontSize: "20px",
           textAlign: "left", // Align the content to the left

           backgroundColor:
             PRIMARY_COLOR,
         }}
         variant="outlined text-light"
         // onClick={() =>
         //   handleSelectValueSelection(
         //     menuOptionValue.option_value_id,
         //     menuOption,
         //     "increment"
         //   )
         // }
       >
         {
           menuOptionValue.OptionValue
             .value
         }
       </Typography> */}

                                                <p
                                                  sx={{
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {
                                                    menuOptionValue.OptionValue
                                                      .value
                                                  }
                                                </p>

                                                <p
                                                  style={{
                                                    marginLeft: "0px", // Add margin to the left side
                                                    textAlign: "left", // Align text to the right
                                                  }}
                                                ></p>
                                              </div>
                                            </div>

                                            {selectValueCounts[
                                              menuOptionValue.option_value_id
                                            ] >= menuOption.min ? (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Button
                                                  variant="outlined text-light"
                                                  onClick={() =>
                                                    handleSelectValueSelection(
                                                      menuOptionValue.option_value_id,
                                                      menuOption,
                                                      "decrement"
                                                    )
                                                  }
                                                  style={{
                                                    fontWeight:
                                                      selectedValues.includes(
                                                        menuOptionValue.option_value_id
                                                      )
                                                        ? "bold"
                                                        : "normal",
                                                    flexShrink: 0,
                                                    minWidth: "2rem",

                                                    height: "1.5rem",
                                                    // borderRadius: "50%",
                                                  }}
                                                >
                                                  -
                                                </Button>
                                                <h1
                                                  variant="body1"
                                                  style={{
                                                    margin: "0 8px",
                                                  }}
                                                >
                                                  {selectValueCounts[
                                                    menuOptionValue
                                                      .option_value_id
                                                  ] || menuOption.min}
                                                </h1>
                                                <Button
                                                  variant="outlined text-light"
                                                  onClick={() =>
                                                    handleSelectValueSelection(
                                                      menuOptionValue.option_value_id,
                                                      menuOption,
                                                      "increment"
                                                    )
                                                  }
                                                  style={{
                                                    fontWeight:
                                                      selectedValues.includes(
                                                        menuOptionValue.option_value_id
                                                      )
                                                        ? "bold"
                                                        : "normal",
                                                    flexShrink: 0,
                                                    minWidth: "2rem",
                                                    height: "1.5rem",

                                                    // borderRadius: "50%",
                                                  }}
                                                >
                                                  +
                                                </Button>
                                              </div>
                                            ) : null}

                                            {/* <Typography
     variant="body1"
     style={{
       marginLeft: "8px", // Add margin to the left side
       textAlign: "right", // Align text to the right
     }}
   >
     <small>
       {menuOptionValue.new_price > 0
         ? restaurantData.currency +
           menuOptionValue.new_price
         : "Free"}
     </small>
   </Typography> */}
                                          </div>
                                        </Card>
                                      )}
                                    </>
                                    {/* )} */}
                                  </>
                                )
                              )}
                              {/* {menuOption.MenuOptionValues.length >
                                  MAX_OPTIONS && (
                                  <div style={{ textAlign: "left" }}>
                                    {!showMore ? (
                                      <Button
                                        variant="text"
                                        onClick={handleViewMore}
                                        style={{ minWidth: "unset" }}
                                      >
                                        {t("view-more")}{" "}
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="text"
                                        onClick={handleViewMore}
                                        style={{ minWidth: "unset" }}
                                      >
                                        {t("view-less")}{" "}
                                      </Button>
                                    )}
                                  </div>
                                )} */}
                            </Form>
                          )}
                          {menuOption.Option.display_type === "radio" &&
                            menuOption.MenuOptionValues.some(
                              (optionValue) =>
                                optionValue?.OptionValue?.status === 1
                            ) && (
                              <>
                                {menuOption.MenuOptionValues.map(
                                  (menuOptionValue, idx) =>
                                    menuOptionValue?.OptionValue?.status ===
                                      1 && (
                                      <Card
                                        key={idx}
                                        style={{
                                          padding: "10px",
                                          margin: "10px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Form
                                          component="fieldset"
                                          className="w-100"
                                        >
                                          <Form.Check
                                            type="radio"
                                            id={menuOptionValue.option_value_id}
                                            name={menuOption.Option.option_name}
                                            value={
                                              menuOptionValue.option_value_id
                                            }
                                            checked={
                                              selectedRadioValues[index] ===
                                              menuOptionValue.option_value_id
                                            }
                                            onChange={(e) =>
                                              handleRadioValueSelection(
                                                index,
                                                e.target.value,
                                                menuOption
                                              )
                                            }
                                            label={
                                              menuOptionValue.OptionValue.value
                                            }
                                          />
                                        </Form>
                                      </Card>
                                    )
                                )}
                              </>
                            )}
                          {menuOption.Option.display_type === "checkbox" &&
                            menuOption.MenuOptionValues.some(
                              (optionValue) =>
                                optionValue?.OptionValue?.status === 1
                            ) && (
                              <>
                                {menuOption?.MenuOptionValues.map(
                                  (menuOptionValue, idx) =>
                                    menuOptionValue?.OptionValue?.status ===
                                      1 && (
                                      <Card
                                        key={idx}
                                        style={{
                                          padding: "10px",
                                          margin: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            id={menuOptionValue.option_value_id}
                                            label={
                                              menuOptionValue.OptionValue.value
                                            }
                                            checked={selectedCheckboxes.includes(
                                              menuOptionValue.option_value_id
                                            )}
                                            onChange={() =>
                                              handleCheckboxValueSelection(
                                                menuOptionValue.option_value_id,
                                                menuOption
                                              )
                                            }
                                          />
                                        </div>
                                      </Card>
                                    )
                                )}
                              </>
                            )}

                          {/* {menuOption.Option.display_type === "radio" && (
                              <Card
                                style={{
                                  padding: "10px",
                                  margin: "10px",
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  className="w-100"
                                >
                                  <RadioGroup
                                    aria-label={menuOption.Option.option_name}
                                    name={menuOption.Option.option_name}
                                    value={selectedRadioValues[index] || ""}
                                    onChange={(e) =>
                                      handleRadioValueSelection(
                                        index,
                                        e.target.value,
                                        menuOption
                                      )
                                    }
                                    className="w-100"
                                  >
                                    {menuOption.MenuOptionValues.map(
                                      (menuOptionValue, idx) =>
                                        menuOptionValue.OptionValue.status ===
                                          1 && (
                                          <div
                                            style={{
                                              // display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <FormControlLabel
                                              key={idx}
                                              value={
                                                menuOptionValue.option_value_id
                                              }
                                              control={<Radio />}
                                              label={
                                                <div>
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      menuOptionValue
                                                        .OptionValue.value
                                                    }
                                                  </span>
                                                </div>
                                                // menuOptionValue.OptionValue.value
                                              }
                                            />
                                            <Typography
                                              variant="body1"
                                              style={{ marginLeft: "30px" }}
                                            >
                                              <small>
                                                {menuOptionValue.new_price > 0
                                                  ? restaurantData.currency +
                                                    calculateTotalPrice(
                                                      menuOptionValue.new_price,
                                                      menuOption.item_tax
                                                    )
                                                  : t("free-1")}
                                              </small>
                                            </Typography>
                                          </div>
                                        )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Card>
                            )} */}
                          {/* {menuOption.Option.display_type === "checkbox" && (
                              <>
                                <Card
                                  style={{
                                    padding: "10px",
                                    margin: "10px",
                                  }}
                                >
                                  {menuOption?.MenuOptionValues.map(
                                    (menuOptionValue, idx) =>
                                      menuOptionValue?.OptionValue?.status ===
                                        1 && (
                                        <div
                                          key={idx}
                                          style={{
                                            // display: "flex",
                                            alignItems: "center",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectedCheckboxes.includes(
                                                  menuOptionValue.option_value_id
                                                )}
                                                onChange={() =>
                                                  handleCheckboxValueSelection(
                                                    menuOptionValue.option_value_id,
                                                    menuOption
                                                  )
                                                }
                                              />
                                            }
                                            label={
                                              <div>
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {
                                                    menuOptionValue.OptionValue
                                                      .value
                                                  }
                                                </span>
                                              </div>
                                            }
                                          />
                                          <Typography
                                            variant="body1"
                                            style={{ marginLeft: "30px" }}
                                          >
                                            <small>
                                              {menuOptionValue.new_price > 0
                                                ? restaurantData.currency +
                                                  calculateTotalPrice(
                                                    menuOptionValue.new_price,
                                                    menuOption.item_tax
                                                  )
                                                : t("free-2")}
                                            </small>
                                          </Typography>
                                        </div>
                                      )
                                  )}
                                </Card>
                              </>
                            )} */}
                        </div>
                      </div>
                    )
                  )
                )}

                <div mt={2}></div>
                <div display="flex">
                  <div>
                    {/* <Button
                        variant="outlined"
                        color="success"
                        onClick={handleDecrement}
                        style={{ minWidth: "30px" }}
                      >
                        -
                      </Button>
                      <FormControl sx={{ width: 35, textAlign: "center" }}>
                        <Input
                          id="quantity-input"
                          type="text"
                          value={quantity}
                          onChange={(e) => setQuantity(Number(e.target.value))}
                          inputProps={{
                            min: 1,
                            style: { textAlign: "center" },
                          }}
                          disabled
                        />
                      </FormControl>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={handleIncrement}
                        style={{ minWidth: "30px" }}
                      >
                        +
                      </Button> */}
                  </div>
                  {requiredFieldError !== "" && requiredFieldError}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Button
                        // variant="contained"
                        onClick={addToCart}
                        style={{
                          fontWeight: "bold",
                          borderRadius: "20px",
                          fontSize: "15px", // Adjust the font size here
                          color: "#FFFFFF",
                        }}
                      >
                        {"add-to-cart"}{" "}
                      </Button>
                    </div>
                    <div>
                      {/* Display total amount or other relevant information */}
                      {/* {totalAmounttotalAmount > 0
      ? restaurantData.currency + totalAmount
      : null} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MenuOptionsModal;
